@import "../variables.less";

// Testimonial
#TestimonialTwo {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 40px 0 0;

  .SmartPhone {
    position: absolute;
    bottom: 0;
    right: 0px;
    max-width: 600px;
  }

  .sliderContainer {
    width: 90%;
    max-width: 1200px;
    min-height: 400px;
    margin: 0 auto;
    padding: 50px 0 0;
    background-repeat: no-repeat;
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    position: relative;

    .sliderTestimonial {
      margin-bottom: 40px;
      margin-left: 20%;
      padding: 40px 20px 20px;
      width: 80%;
      border: 1px solid @white;
      border-radius: 20px;
      position: relative;

      .fixIcon {
        position: absolute;
        top: -50px;
        left: 20px;
        width: 80px;
        height: 80px;
      }
    }

    .slick-dots {
      bottom: -50px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 0;

      li {
        margin: 10px 0 0;

        button {
          padding: 0;
          margin: 0;
        }

        button:before {
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 10px;
          border: 1px solid @white;
          opacity: 1;
          background-color: transparent;
        }

        &.slick-active {
          button:before {
            width: 10px;
            height: 10px;
            background-color: @white;
          }
        }
      }
    }

    .slick-slider {
      bottom: 0;
      left: 0;
    }

    .slick-track {
      display: flex;
      align-items: flex-start;
    }

    .testimonialItem {
      text-align: left;
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      position: relative;
      overflow: visible;

      p {
        color: @white;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        text-align: left;
      }

      .title {
        padding: 0 0;
        color: @white;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.3;
        text-align: left;
        width: 100%;
      }

      .post {
        color: @text_dark_black;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: 0.032rem;
      }

      .photo {
        width: 90px;
        height: 90px;
        border-radius: 90px;
        display: block;
      }
    }

    .slick-prev:before,
    .slick-next:before {
      color: @primary-color;
      font-size: 3rem;
    }
  }

  @media (max-width: @screen-xl) {
    .SmartPhone {
      max-width: 450px;
    }
  }

  @media (max-width: @screen-lg) {
    .sliderContainer {
      overflow: hidden;
      background-position: bottom right;

      .sliderTestimonial {
        background-color: fade(@primary-color, 45%);
        margin: 20px auto;

        .fixIcon {
          width: 60px;
          height: 60px;
          top: -30px;
        }

        .testimonialItem {
          .title {
            font-size: 1.8rem;
            font-weight: 800;
          }
        }
      }

      .SmartPhone {
        margin: 30px auto -10px;
        max-width: 400px;
        position: relative;
      }
    }
  }

  @media (max-width: @screen-md) {
    .sliderContainer {
      background-position: bottom left;
    }
  }

  @media (max-width: @screen-sm) {
    padding: 25px 0 0 0;

    .sliderContainer {
      padding: 20px 0;
      .sliderTestimonial{
        width: 90%;
      }
    }
  }
}