.titleTag {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: @tagColor;
  text-transform: uppercase;

  &.leftLine {
    justify-content: flex-start;

    &::after {
      content: "";
      width: 50px;
      height: 2px;
      background-color: @primary-color;
      display: inline-block;
    }
  }

  &.leftRightLine {
    justify-content: center;

    &::before,
    &::after {
      content: "";
      width: 50px;
      height: 2px;
      background-color: @primary-color;
      display: inline-block;
    }
  }

  &.primaryColor {
    color: @primary-color;
  }
}

.titleSection {
  color: @titleColor;
  font-size: 4.4rem;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

a.buttonLink,
.buttonLink {
  padding: 1.2rem 2.5rem;
  margin: 0 30px 0 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 60px;
  border: 1px solid @text_dark_black;
  color: @text_dark_black;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.4;
  height: auto;

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: @text_dark_black;
    }
  }

  &.primaryColor {
    background-color: @primary-color;
    border-color: @primary-color;
    color: @white;
    box-shadow: 0px 4px 24px 0px @primary-light-color;

    svg path {
      fill: @white;
    }

    &.outline {
      background-color: @white;
      border-color: @primary-color;
      color: @primary-color;
      box-shadow: none;

      svg path {
        fill: @primary-color;
      }

      &:is(:hover, :focus-within) {
        background-color: @secondary-color;
        border-color: @secondary-color;
        color: @white;

        svg path {
          fill: @white;
        }
      }
    }
  }

  &.secondaryColor {
    background-color: @white;
    border-color: @secondary-color;
    color: @secondary-color;
    box-shadow: none;

    svg path {
      fill: @secondary-color;
    }

    &:is(:hover, :focus-within) {
      background-color: @primary-color;
      border-color: @primary-color;
      color: @white !important;

      svg path {
        fill: @white;
      }
    }
  }

  &:is(:hover, :focus-within) {
    background-color: @secondary-color;
    border-color: @secondary-color;
    color: @white;

    svg path {
      fill: @white;
    }
  }

  @media (max-width: @screen-sm) {
    padding: 0.6rem 1rem;
    font-size: 1.4rem;
    
  }

}

@media (max-width: @screen-lg) {
  .titleSection {
    font-size: 2.8rem;
    font-weight: 800;
    line-height: 1.3;
    letter-spacing: 0.032em;
    margin-top: 10px;
  }
}
  @media (max-width: @screen-sm) {
    .titleSection {
      font-size: 1.7rem;
      
    }
  }

.circles {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: -200px;
  right: auto;
  transform: translateY(-50%);

  >div {
    animation: grow-and-fade 3s infinite ease-out;
    background-color: fade(@secondary-color, 80%);
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  .circle1 {
    animation-delay: 1s;
  }

  .circle2 {
    animation-delay: 2s;
  }

  .circle3 {
    animation-delay: 3s;
  }
}

.pagenotfound__section {
  width: 100dvw;
  height: 100dvh;
  display: grid;
  place-items: center;
}

.banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding:  0 1rem;
  text-align: center;
  min-height: 28rem;
  background-color: @white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  &::after{
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0px 8px 20px 30px rgba(255,255,255, 0.9);
  }

  .title {
    font-family: @primary-font;
    color: @white;
    font-size: 7.2rem;
    line-height: normal;
    font-weight: @font-weight-600;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    &::after,
    &::before {
      content: "";
      height: 0.2rem;
      width: 9rem;
      background-color: @white;
      display: inline-block;
    }


  }

  h3 {
    font-size: 3.7rem;
    font-weight: @font-weight-600;
    color: @primary-color;
    margin: 0 0 5rem 0;
  }

  h4 {
    color: @black;
    font-size: 2.2rem;
    font-weight: @font-weight-400;
    margin: 0;
  }

  @media (max-width:@screen-sm) {
    padding: 5rem 0 ;

    min-height: 17.2rem;

    .title {
      font-size: 3rem;
      margin: 1rem;

      &::before,
      &::after {
        width: 5rem;
      }
    }

    h3 {
      font-size: 1.9rem;
    }

    h4 {
      font-size: 1.6rem;
    }
  }
}
.Snowfall{
  canvas{
    z-index: 999;
  }
}
