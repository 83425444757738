.container,
.container-fluid {
  max-width: 100%;
  width: 100%;
  padding-right: @gutter;
  padding-left: @gutter;
  margin-right: auto;
  margin-left: auto;
}

.container {
  @media (max-width: @screen-md) {
    max-width: @screen-md;
  }
  @media (max-width: @screen-lg) {
    max-width: @screen-lg;
  }
  @media (max-width: @screen-xl) {
    max-width: @screen-xl;
  }
  @media (max-width: @screen-xxl) {
    max-width: @screen-xxl;
  }
}
