.mainFooter {
  position: relative;
  margin: 60px 0 0;
  padding: 40px 0 50px;
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: auto 200px;
  text-shadow: 0px 0px 15px @white;
  .container {
    max-width: 1600px;
    padding: 0 50px;
  }

  .footerTitle {
    color: @black;
    font-size: 2.6rem;
    font-weight: 600;
    line-height: 1.4;
    position: relative;
    padding: 0 0 20px;
    margin: 0 0 30px;
    &::before {
      content: "";
      width: 7px;
      height: 7px;
      border-radius: 7px;
      background-color: @primary-color;
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0;
    }
    &::after {
      content: "";
      width: 40px;
      height: 1px;
      background-color: @primary-color;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .footerMenu {
    margin: 0;
    padding: 0;
    li {
      width: 100%;
      margin-bottom: 20px;

      a {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        font-size: 1.6rem;
        line-height: 1.4;
        font-weight: 400;
        color: @text_light_black;
        svg {
          width: 14px;
          height: 14px;
          transition: all 0.5s ease-in-out;
          path {
            fill: @secondary-color;
          }
        }
        &:is(:hover, :focus-within) {
          color: @primary-color;
          svg {
            transform: rotate(45deg);
            path {
              fill: @primary-color;
            }
          }
        }
      }
    }
  }

  .contact {
    li {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 15px;
      font-size: 1.6rem;
      line-height: 1.4;
      font-weight: 400;
      svg {
        width: 14px;
        height: 14px;
        transition: all 0.5s ease-in-out;
        path {
          fill: @secondary-color;
        }
        &.address {
          width: 35px;
          height: 30px;
        }
      }
      a {
        color: @text_light_black;
      }
      &:is(:hover, :focus-within) {
        a {
          color: @primary-color;
        }
        svg path {
          fill: @primary-color;
        }
      }
    }
  }
  .summary {
    font-size: 1.5rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.4;
    color: @text_light_black;
    margin-bottom: 20px;
  }
  .firstSection {
    padding: 20px 0;
    .siteLogo {
      width: 80%;
      max-width: 250px;
      display: block;
      margin-bottom: 20px;
      margin-top: -40px;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
  .social {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
    li {
      width: 42px;
      height: 42px;
      border-radius: 42px;
      border: 1px solid #dedede;
      transition: all 0.5s ease-in-out;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 20px;
        svg {
          width: 45%;
          height: 45%;
          path {
            fill: @text_light_black;
            transition: all 0.5s ease-in-out;
          }
          &.stroke {
            path {
              stroke: @text_light_black;
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }
      &:is(:hover, :focus-within) {
        background-color: @secondary-color;
        a svg {
          path {
            fill: @white;
          }
          &.stroke {
            path {
              stroke: @white;
            }
          }
        }
      }
    }
  }
  .serviceSection {
    position: relative;
  }

  .subscribeForm {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: @white;
    border-radius: 50px;
    box-shadow: 0px 4px 22px 0px fade(@black, 7%);

    .ant-form-item {
      margin: 0;
      position: relative;
      width: 100%;
      .ant-form-item-explain {
        position: absolute;
        left: 10px;
        bottom: -25px;
      }
    }
    input {
      width: 100%;
      height: 100%;
      border-radius: 50px 0 0 50px;
      background-color: transparent;
      padding: 1.5rem 6%;
    }
    button {
      height: 100%;
      border-radius: 0 50px 50px 0;
      background-color: @primary-color;
      padding: 16.5px 5%;
    }
  }
  @media (max-width: @screen-lg) {
    .container {
      padding: 0 20px;
    }
    .summary {
      font-size: 1.4rem;
    }
  }
  @media (max-width: @screen-sm) {
    margin: 7rem 0 0 0;
    padding: 0;
  }
}
.copyright {
  background-color: #ededed;
  text-align: center;
  color: @text_light_black;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.3;
  width: 100%;
  padding: 15px 20px;
  a {
    color: @text_light_black;
    &:is(:hover, :focus-within) {
      color: @primary-color;
    }
  }
}
