@import "../variables.less";

#aboutUsPage {

    .banner {
        background-color: @white;
    }

    .container {
        max-width: 1600px;
    }

    .subTitle {
        color: @primary-color;
        font-size: 1.6rem;
        font-weight: @font-weight-600;
        text-transform: uppercase;
        line-height: 1.4;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin: 0;

        &::after {
            content: "";
            height: 0.2rem;
            width: 6rem;
            background-color: @primary-color;
            display: inline-block;
        }
    }

    .title {
        font-size: 4.4rem;
        font-weight: @font-weight-600;
        text-transform: capitalize;
        margin-top: 0;
    }

    .content {
        margin: 0 0 2rem 0;
        font-size: 1.6rem;
        font-weight: @font-weight-400;
        color: #545454;
        line-height: 1.6;
    }


    .whoWeAre {
        background-color: @white;
        padding-bottom: 8rem;
        position: relative;
        z-index: 9;

        &::after {
            content: "";
            background-image: url("../../images/aboutDotBollOne.svg");
            height: 20rem;
            width: 20rem;
            left: 0;
            bottom: 50%;
            position: absolute;
            z-index: -1;
            animation: diving-rotate 4s ease-in-out infinite;
        }



        .rightContent {
            position: relative;


            .aboutBigImg {
                width: 100%;

                .aboutFirst {
                    object-fit: cover;
                    object-position: center;
                    display: block;
                    width: 100%;
                    height: auto;
                    position: relative;
                    z-index: 0;
                }
            }



            .imageContent {
                display: inline-table;
                margin: 1rem 0 0;
                padding: 0 25px;
                position: relative;
                z-index: 5;
            }

            .experience,
            .experienceImage {
                display: table-cell;
                width: 50%;
                border-radius: 2rem;
            }

            .experienceImage {

                .aboutSecond {
                    object-fit: cover;
                    object-position: center;
                    margin-top: -11rem;
                    margin-bottom: -2rem;
                    border-radius: 2rem;
                    border: 1.2rem solid @white;
                }
            }

            .experience {
                text-align: center;
                background-color: @primary-color;
                vertical-align: middle;


                .experienceContent {
                    font-size: 2rem;
                    color: @white;
                    font-weight: @font-weight-600;
                    padding: 2rem 0;
                    margin: 0;

                    span {
                        font-size: 280%;
                        line-height: 1.4;
                        display: block;
                    }


                }
            }

        }
    }

    .counter {
        margin-top: 6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 6rem 1rem;
        flex-wrap: wrap;

        .counterSection {
            position: relative;
            isolation: isolate;

            .counterUp {
                position: relative;
                font-family: @secondary-font !important;
                color: transparent;
                -webkit-text-stroke: 1px @black;
                -webkit-text-fill-color: transparent;
                font-size: 8.4rem;
                line-height: 1;
                font-weight: @font-weight-700;

                &::after {
                    content: "";
                    display: inline-block;
                    height: 7rem;
                    width: 7rem;
                    border-radius: 7rem;
                    margin-left: -7rem;
                    background-color: rgba(36, 144, 235, 0.20);
                    position: absolute;
                    top: 1.5rem;
                    left: 95%;
                    z-index: -1;
                }
            }

            p {
                font-weight: @font-weight-600;
                text-transform: capitalize;
                font-size: 1.7rem;
                color: @secondary-color;
            }

            svg {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    .prideSection {
        background-color: @layout-body-background;
        padding: 12rem 0;
        position: relative;
        isolation: isolate;
        z-index: 2;

        .tropy {
            position: absolute;
            z-index: 1;
            bottom: 4rem;
            height: 16rem;
            width: 18rem;
            left: 5rem;
        }

        &::before {
            position: absolute;
            content: "";
            background-image: url("../../images/certifiedDots.png");
            right: 0;
            top: 2rem;
        }



        .certified {

            max-height: 50rem;
            overflow-y: scroll;
            padding: 6rem 2rem 0 0;

            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: @primary-color;
            }
        }

        .certifiedInnerContent {
            background-color: @white;
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.07);
            border-radius: 1rem;
            margin-bottom: 8rem;
            padding: 1rem 2rem;

            &:last-child {
                margin-bottom: 1rem;
            }



            .certifiedImgTitle {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 1.7rem;

                .certifiedImg {
                    height: 12.3rem;
                    width: 12.3rem;
                    margin-top: -7rem;
                    object-fit: cover;
                    object-position: center;
                }

                .certifiedTitle {
                    font-size: 2.2rem;
                    font-weight: @font-weight-600;
                    color: @primary-color;
                    text-transform: capitalize;
                    margin: 0;
                }
            }

            .certifiedContent {

                p {
                    color: @black;
                    font-size: 1.6rem;
                    font-weight: @font-weight-400;
                    line-height: 1.6;
                }
            }
        }
    }

    .founderSection {
        background-color: @white;
        padding: 5rem 0 20rem 0;
        position: relative;
        isolation: isolate;

        &::after {
            position: absolute;
            content: url("../../images/verticallyDots.png");
            left: 10rem;
            top: 20rem;
            z-index: -1;
            animation: diving-rotate 4s ease-in-out infinite;
        }

        &::before {
            position: absolute;
            content: url("../../images/verticallyDots.png");
            right: 10rem;
            bottom: 7rem;
            z-index: -1;
            animation: diving-rotate 4s ease-in-out infinite;
        }

        .subTitle,
        .title {
            text-align: center;
            width: 100%;
            margin: 0;
        }

        .founderContent {
            display: flex;
            align-items: center;
            gap: 5rem 9rem;
            flex-wrap: wrap;
            justify-content: center;



            .founderImg {
                width: 32.5rem;
                height: 41.2rem;
                border-radius: 2rem;
               
                margin: 5rem 0;
                position: relative;
                z-index: 2;

                &::after {
                    content: "";
                    position: absolute;
                    background-color: @white;
                    width: 32.5rem;
                    height: 41.2rem;
                    transform: rotate(-8deg);
                    z-index: -1;
                    left: 0;
                    border-radius: 2rem;
                    transition: 0.5s all ease-in-out;
                    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
                }

                img {
                    transition: 0.5s all ease-in-out;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: right center;
                    background-color: #E8E8E8;
                    z-index: 1;
                    border-radius: 2rem;
                    
                }

                &:hover {
                    &::after {
                        transform: rotate(0);
                    }

                    img {
                        transform: rotate(0);
                    }
                }
            }
        }

        .founderName {
            font-size: 2.6rem;
            font-weight: @font-weight-500;
            text-transform: capitalize;
            margin: 0;
        }

        .director {
            color: #727272;
            font-size: 1.6rem;
            font-weight: @font-weight-400;
            text-transform: capitalize;
            margin: 0;
        }

    }

    @media (max-width:@screen-xl) {
        .counter {
            .counterSection {
                width: 40%;
            }
        }

        .founderSection {

            &::after {
                left: 2rem;
            }

            &::before {
                right: 4rem;
            }
        }

        .prideSection {
            .tropy {
                height: 12rem;
                width: 15rem;
                bottom: 1rem;
            }
        }
    }

    @media (max-width:@screen-md) {
        .prideSection {
            .tropy {

                display: none;
            }
        }
        // .founderSection {
        //     padding: 5rem 0;

        //     .founderContent {
        //         gap: 1rem;

        //         .founderInnerContent {
        //             text-align: center;
        //             width: 100%;
        //         }

        //         .founderImg {
        //             width: 100%;
        //             height: 51.2rem;
        //             margin: 2.5rem 0;

        //             img {
        //                 object-position: top;
        //             }

        //             &::after {
        //                 width: 100%;
        //                 height: 50.2rem;
        //             }
        //         }
        //     }

        //     &::after {
        //         display: none;
        //     }

        //     &::before {
        //         display: none;
        //     }


        // }
    }

    @media (max-width:(@screen-sm)) {
        .container {
            padding: 0 20px;
        }


        .title {
            font-size: 2.5rem;
        }

        .content {
            font-size: 1.6rem;
        }

        .counter {
            text-align: center;
            gap: 2rem;

            .counterSection {
                width: 100%;

                .counterUp {
                    font-size: 4rem;

                    &::after {
                        height: 4rem;
                        width: 4rem;
                        border-radius: 4rem;
                        margin-left: -4rem;
                        top: 0.5rem;
                    }
                }
            }
        }

        .whoWeAre {
            padding-bottom: 4rem;
        }

        .whoWeAre .rightContent {
            .experience {
                .experienceContent {
                    font-size: 1.7rem;
                    padding: 1rem;

                    span {
                        font-size: 220%;
                    }
                }
            }

            .experienceImage {
                .aboutSecond {
                    border: .8rem solid @white;
                    margin-top: -6rem;
                }
            }
        }

        .prideSection {
            padding: 6rem 0;

            .tropy {
                display: none;
            }

            .certified {
                padding: 6rem 1rem 0 0;
            }



            .certifiedInnerContent {
                .certifiedImgTitle {
                    flex-direction: column;

                    .certifiedTitle {
                        font-size: 2rem;
                    }
                }

                .certifiedContent {

                    p {
                        font-size: 1.4rem;
                    }
                }
            }
        }
        .founderSection {
            padding: 5rem 0;

            .founderContent {
                gap: 1rem;

                .founderInnerContent {
                    text-align: center;
                }

                .founderImg {
                    width: 100%;
                    height: 100%;
                    margin: 2.5rem 0;

                    img {
                        object-position: top center;
                    }

                    &::after {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            &::after {
                display: none;
            }

            &::before {
                display: none;
            }


        }
       
    }

}