@import "../variables.less";


#blogDetails {
    background-color: @white;
    padding-bottom: 5rem;
    h1{
        font-size: 32px;
        font-weight: 600;
    }

    .main {
        position: relative;

        .Dotted_1 {
            position: absolute;
            animation: diving-rotate 4s ease-in-out infinite;
        }
    }


    .container {
        max-width: 1400px;
    }

    .blogDetail {
        border-radius: 20px;

    }

    .featureImg {
        height: 44.1rem;
        position: relative;
        isolation: isolate;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 20px 20px 0px 0px;
        }

    }

    .featureDate {
        position: absolute;
        bottom: -1.5rem;
        left: 5rem;
        background-color: @primary-color;
        color: @white;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
        align-items: center;
        font-weight: 500;

        .date {
            font-weight: 500;
            font-size: 1.6rem;
            color: @white;
        }
    }

    .content {
        border: 1px solid rgba(0, 0, 0, 0.20);
        padding: 3.5rem;

        img {
            height: auto;
        }
    }

    .h2 {
        font-size: 3.1rem;
        color: @black;
        font-weight: @font-weight-500;
        text-transform: capitalize;

    }

    .blogDetailRight {
        border-radius: 2rem;
        border: 1px solid rgba(0, 0, 0, 0.20);

        padding: 1.9rem;
    }

    .rightTitle {
        font-size: 2rem;
        color: @black;
        font-weight: 500;
    }




    .recentBlogImg {
        width: 7.6rem;
        height: 7.1rem;
        min-width: 7.6rem;


        img {
            height: 100%;
            border-radius: 1rem;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .recentBlogContent {
        display: flex;
        align-items: center;
        gap: 1rem;
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
        padding-bottom: 2rem;
        margin-bottom: 2rem;
    }

    .contantTitle {
        font-size: 1.8rem;
        color: @black;
        text-transform: capitalize;
        transition: 0.5s all ease-in-out;
    }

    .recentBlogContant {
        &:hover {
            .contantTitle {
                color: @primary-color;
                cursor: pointer;
            }
        }
    }

    .recentBlogDate {
        margin-top: 0.5rem;
    }

    .date {
        font-size: 1.4rem;
        color: #727272;
        font-weight: 400;
        text-transform: capitalize;
        margin-left: 0.6rem;
    }

    .social {
        display: flex;
        align-items: center;
        gap: 1rem;

        .socialIcon {
            background-color: #ECECEC;
            height: 4.8rem;
            width: 4.8rem;
            border-radius: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 1.6rem;
                height: 1.6rem;
                object-fit: contain;
                object-position: center;

                path {
                    fill: #565656;
                    transition: all 0.5s ease-in-out;
                }

                &.stroke {
                    path {
                        fill: transparent;
                        stroke: #565656;
                        transition: all 0.5s ease-in-out;
                    }
                }
            }

            &:hover {
                background-color: @primary-color;

                svg {
                    path {
                        fill: @white;
                    }

                    &.stroke {
                        path {
                            fill: transparent;
                            stroke: @white;
                        }
                    }
                }

            }
        }


    }

    .email {
        display: none;
        .input_box {

            position: relative;
        }

        input {
            margin-top: 2rem;
            border: none;
            padding: 2.8rem 1.6rem;
            width: 100%;
            background-color: #ECECEC;
            border-radius: 10rem;
            position: relative;
            isolation: isolate;
        }

        .sendEmail {
            position: absolute;
            right: 0px;
            top: 2.4rem;
            border: none;
            background: transparent;

            &:hover {
                cursor: pointer;
            }

        }

    }

    .tags {
        .allTags {
            display: flex;
            align-items: center;
            gap: 2rem 1.6rem;
            flex-wrap: wrap;
        }

        .tagasName {
            color: @black;
            background-color: rgba(217, 217, 217, 0.20);
            border-radius: 100px;
            padding: 0.9rem;
            font-size: 1.6rem;

        }
    }

    @media (max-width: @screen-md) {
        .container {
            padding: 0 1.5rem;
        }
        .featureImg {
            height: 30.1rem;
        }
        .recentBlogImg {
            width: 10.6rem;
            height: 10.1rem;
        }
        .featureDate {

            left: 2rem;

            .date {

                font-size: 1.4rem;

            }
        }

        .content {
            padding: 1.5rem;
        }

        .rightTitle {
            font-size: 1.8rem;
        }

        .contantTitle {
            font-size: 1.7rem;
        }
    }
   
}