@keyframes grow-and-fade {
  0% {
    opacity: 0.25;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes diving-rotate {
  0%,
  100% {
    transform: rotate(0deg);
    margin-top: 5px;
  }
  25%,
  75% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-2deg);
    margin-top: 25px;
  }
}

@keyframes bubbles {
  0%,
  100% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}
