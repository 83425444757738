@import "../variables.less";

#coreFeatures {
    position: relative;
    padding: 11.8rem 0;

    .leftContent {
        color: #565969;
        line-height: 1.6;
        margin: 0;
        font-size: 1.6rem;
    }
    .buttonLink{
        margin-top: 6.5rem; 
    }

    .buttonPrimary {
        // margin-top: 6.5rem;
        color: #fff;
        background-color: #2490eb;
        border-color: #2490eb;
        box-shadow: 0px 4px 24px 0px #b4ddff;
        height: auto;

        svg {
            width: 12px;
            height: 12px;

            path {
                fill: @white;
            }
        }
    }

    &::after {
        content: "";
        display: block;
        width: 100dvw;
        height: 700px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 54%);
        background-color: #b4ddff;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: 0.3;
    }

    .container {
        max-width: 1850px;
        position: relative;
        z-index: 1;
    }

    .title {
        font-size: 4.4rem;
        font-weight: @font-weight-600;
        text-transform: capitalize;
        margin: 0 0 1rem 0;
        font-weight: 800;
    }

    .card {        
        position: relative;
        
      .cardInnerContent {
        padding: 0px 5px;
      }
        &::after {
            position: absolute;
            content: "";
            height: 172px;
            width: 172px;
            background: #b4ddff;
            border-radius: 100%;
            opacity: 0.3;
            left: 0;
            bottom: 20%;
            z-index: -1;
        }

        .cardContent {
            // max-width: 28.5rem;
            width: 100%;
            background-color: @white;
            padding: 1.5rem;
            text-align: center;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            border-radius: 0.5rem;
            min-height: 40rem;
            transition: all 0.5s ease-in-out;
            margin: 5rem 0;



            .cardIcon {
                height: 8.4rem;
                width: 8.4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #F5F7FA;
                border-radius: 50%;
                margin: 0 auto;

                svg {
                    height: 5rem;
                    width: 5rem;
                }

            }

            h3 {
                font-size: 2rem;
                font-weight: 600;
                position: relative;
                margin: 26px 0;

                &::after {
                    content: "";
                    position: absolute;
                    border-bottom: 0.2rem solid @primary-color;
                    left: 0;
                    right: 0;
                    bottom: -0.5rem;
                    width: 3rem;
                    margin: 0 auto;
                }
            }

            p {
                font-size: 1.6rem;
                color: #545454;
                line-height: 1.6;
            }
            &:hover{
                transform: translateY(-20px);   
                box-shadow: 0px 30px 30px -10px rgba(0, 0, 0, 0.15);
            }
        }
    }
    .slick-dots {
        width: 100%;
        max-width: 1200px;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        padding: 0;
        margin: 0 auto;
        display: flex !important;
        align-items: center;
        justify-content: center;
        // background-color: fade(@action_btn_color, 10%);
        border-radius: 7px;
  
        li {
          width: 100%;
          height: 2px;
          margin: 0;
  
          button {
            padding: 0;
            margin: 0;
            width: 100%;
  
            &:before {
              width: 100%;
              height: 2px;
              background-color: fade(@action_btn_color, 10%);
              opacity: 1;
              content: "";
            }
          }
  
          &:first-child {
            button:before {
              border-radius: 7px 0 0 7px;
            }
          }
  
          &:last-child {
            button:before {
              border-radius: 0 7px 7px 0;
            }
          }
  
          &.slick-active {
            button:before {
              border-radius: 7px;
              height: 5px;
              margin: -2px 0 0;
              background-color: @primary-color;
              opacity: 1;
            }
          }
        }
      }

    @media (max-width:992px) {
        padding: 5rem 0;

        .buttonPrimary {
            margin-top: 2.5rem;
        }

        .buttonLink{
            margin-top: 2.5rem;
        }
    
        .card {
            margin-top: 5rem;
            justify-content: center;

            .cardContent {
                max-width: 100%;
                width: 100%;
                margin: 2rem 0;
            }
        }
    }
    @media (max-width:@screen-xl) {
        padding: 9rem 0 ;
        .card {
            margin-top: 1rem;
            justify-content: center;

            .cardContent{
                margin: 1rem 0;
            }
        }

    }

    

    @media (max-width:@screen-lg) {
        padding: 5rem 0;

        .title {
            font-size: 2.8rem;
            margin: 1rem 0;
        }

        .buttonPrimary {
            margin-top: 2.5rem;
        }

        .card {

            .cardContent {
                max-width: 100%;
                width: 100%;
            }
        }
    }

    @media (max-width:@screen-sm) {
        padding: 5rem 0;

        .title {
            font-size: 1.7rem;
        }

        .buttonPrimary {
            margin-top: 2.5rem;
        }

        .card {
            margin-top: 5rem;
            justify-content: center;

            .cardContent {
                max-width: 100%;
                width: 100%;
            }
        }
    }

}