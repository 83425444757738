.demoForm {
    position: relative;

    .featuresOfFieldLines {
        position: absolute;
        top: 0;
    }

    .squreDotsRight {
        position: absolute;
        right: 0;
        top: -50px;
    }

    .reportSection {
        margin: 0 0 10rem 0;
        padding: 3rem 3rem 0 3rem;
    }

    .miniContainer {
        max-width: 1400px;
        margin: 0 auto;
    }

    .crmServiceTitle {
        color: @white;
        font-family: @primary-font;
        font-size: 3.2rem;
        font-style: normal;
        font-weight: 600;
        text-transform: capitalize;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            border-bottom: 0.2rem solid @white;
            height: auto;
            width: 4rem;
        }
    }

    .crmServiceList {
        color: @white;
        font-family: @secondary-font;
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;
        line-height: 1.8;

        svg {
            transform: rotate(45deg);
            margin-right: 0.5rem;
        }
    }

    .contactForm {
        // transform: translateY(-105px);
        margin-top: -105px;
        padding: 2rem 3rem;
        position: relative;

        .crmServiceTitle {
            color: @black;
            margin: 0 0 2rem 0;

            &:after {
                border-bottom: 0.2rem solid @primary-color;
            }
        }

        background: @white;
        border-radius: 2rem;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);

        .nameInput {
            display: flex;
            align-items: center;
            gap: 2rem;
        }

        label {
            color: @text_light_black;
            font-family: @primary-font;
            font-size: 1.7rem;
            font-style: normal;
            font-weight: 400;
            text-transform: capitalize;
        }

        input,
        textarea {
            padding: 0;
            background: transparent;
            border-bottom: 1px solid @black;
            border-radius: 0;
        }

        .ant-btn-default {
            padding: 0.8rem 1.5rem;
            margin-bottom: 24px;
            width: 100%;
            height: auto;
            border-radius: 10px;
            background-color: #2490eb;
            color: #fff;
            border: 1px solid #2490eb;

            img {
                display: none;
            }

            &:hover {
                background-color: #71bf44;
                border-color: #71bf44;
                color: #fff;
            }
        }
    }

    .forCitizens {
        background: linear-gradient(270deg, #29D3F6 -9.11%, #419AFE 46.07%, #A3E2A5 96.84%);
        position: relative;
        z-index: 2;
        width: 100%;
        text-align: center;
        transform: translateY(13rem);
        padding: 7rem 0;
        border-radius: 2rem;

        .requiredTwo {
            position: absolute;
            left: 5rem;
            top: 0;
        }

        .CitizensDots {
            position: absolute;
            right: 6rem;
            top: 4rem;
        }

        .CitizensDotsBottom {
            position: absolute;
            left: 5rem;
            bottom: 2rem;
        }

        .subTitle {
            color: @white;
            font-family: @primary-font;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 400;
            margin: 0;
            list-style: 1.5;
            text-transform: capitalize;

            &::after {
                display: none;
            }
        }

        h2 {
            margin: 0;
            color: @white;
            font-family: @primary-font;
            font-size: 4.1rem;
            font-style: normal;
            font-weight: 600;
            list-style: 1.5;
            text-transform: uppercase;
        }
    }
}

.demoForm {
    .contactForm {
        .nameInput {
            .ant-form-item {
                width: 100%;
                max-width: 50%;
            }
        }

        textarea,
        input {
            width: 100%;
            height: auto;
            background-color: #fff;
            border-radius: 12px;
            padding: 1rem 1.5rem;
            color: #000;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.3;
            resize: none;
            border-width: 1px;
            border-style: solid;
            border-color: #d9d9d9;

            &:focus {
                border-color: #4096ff;
                box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
                outline: 0;
            }
        }
    }
}

.demoBtn {
    margin: 2rem 0 0 0;
}

.reportSection {
    background-color: #2490EB;
    color: @white;
    padding: 5rem 3rem 7rem 3rem;
    z-index: 2;
    position: relative;
    margin-top: 10rem;

    .squreDotsRight {
        position: absolute;
        right: 0;
        top: 0;
        animation: diving-rotate 4s ease-in-out infinite;
    }

    .squreDotsLeft {
        position: absolute;
        left: 0;
        top: 0;
        animation: diving-rotate 4s ease-in-out infinite;
    }

    .firstLine {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
    }

    .secondLine {
        position: absolute;
        top: 40%;
        right: 0;
        z-index: -1;
    }

    .titleContent {
        text-align: center;

        .reportTitle {
            font-family: @primary-font;
            font-size: 4.8rem;
            font-weight: 600;
            margin: 0 auto;
            text-transform: capitalize;
            max-width: 1278px;
            width: 100%;
            line-height: 1;
            margin-bottom: 1.6rem;
        }

        .reportSubTitle {
            color: @white;
            font-family: @primary-font;
            font-size: 1.6rem;
            margin: 0;
            font-weight: 600;
            margin-bottom: 2rem;
            text-transform: uppercase;
        }

        .reportContent {
            max-width: 960px;
            width: 100%;
            color: @white;
            text-align: center;
            font-family: @secondary-font;
            font-size: 1.6rem;
            font-weight: 400;
            display: inline-block;
            text-transform: capitalize;
            margin: 0 auto;
        }
    }

    .crmService {
        display: flex;
        gap: 3rem;
        justify-content: center;
        margin-top: 100px;

        // .crmServiceContentImage {
        //     max-width: 42rem;

        //     img {
        //         width: 100%;
        //         object-fit: cover;
        //     }
        // }
        .crmServiceContent {
            background-color: @white;
            width: 100%;
            max-width: 31.5%;
            border-radius: 25px;
            padding: 25px;
            text-align: center;

            p {
                color: #565969;
                font-size: 18px;
                font-weight: @font-weight-400;
            }

            .crmServiceContentImage {
                height: 134px;
                width: 134px;
                background: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                margin: -80px auto 0 auto;
                border: solid 10px #2490eb;

                img {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .crmServiceTitle {
            font-family: @primary-font;
            margin: 0;
            text-transform: capitalize;
            position: relative;
            line-height: 1.8;
            color: @text_dark_black;
            font-size: 26px;
            font-weight: 600;
            margin: 20px 0;
        }

        .crmServiceList {
            margin-top: 1rem;
            line-height: 1.7;

            svg {
                transform: rotate(45deg);
                margin-right: 0.5rem;
            }
        }

    }

    .requiredRightCircle {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
        opacity: 0.3;
    }
}

@media (max-width: @screen-xl) {
    .reportSection {
        .crmService {
            gap: 2rem;

            .crmServiceContent {
                padding: 10px;
            }
        }
    }

    .gpsAttendanceTitle {
        width: 60%;

        .title {
            font-size: 40px;
        }
    }
}

@media (max-width: @screen-sxl) {
    .reportSection {
        .crmService {
            z-index: 1;
            position: relative;
            flex-wrap: wrap;

            .crmServiceContent {
                width: 100%;
                max-width: 48%;
                padding: 20px;
                margin-bottom: 50px;

                &:last-child {
                    margin-bottom: 0;
                }

                .crmServiceTitle {
                    line-height: 30px;
                    font-size: 24px;
                }

                .discription {
                    font-size: 16px;
                }
            }
        }
    }

    .gpsAttendance-main {
        flex-wrap: wrap;

        .gpsAttendanceTitle {
            width: 100%;
            margin-bottom: 30px;
        }
    }

}

@media (max-width: @screen-lg) {
    .reportSection {
        .titleContent {
            .reportTitle {
                font-size: 3.5rem;
            }
        }
    }

    .gpsAttendance {
        .title {
            font-size: 3.5rem;
        }
    }

    .featuresOfField {
        .fieldInspectionContent {
            h2 {
                font-size: 4.1rem;
            }
        }
    }

    .reportSection {
        .crmService {
            .crmServiceContentImage {
                max-width: 30rem;
            }
        }
    }

    .demoForm {
        .contactForm {
            margin-top: 0;
        }

        .forCitizens {
            .CitizensDots {
                right: -10rem;
            }
        }
    }

}

@media (max-width: @screen-md) {
    .demoForm .reportSection {

        padding-left: 20px;
        padding-right: 20px;
    }

    .Road-Checking-Module-list {
        margin-top: 30px;

        .citizensPicture {
            .arrowIcon {
                width: 50px;
            }

            .citizensPictureInner {
                .citizensImages {
                    width: 50px;
                    height: 50px;
                }

                .citizensContent {
                    width: calc(100% - 50px);

                    h3 {
                        font-size: 20px;
                        line-height: 28px;
                    }
                }
            }

        }
    }

    .gpsAttendance {
        &::after {
            height: 126px !important;
            width: 126px !important;
            left: -50px !important;

        }
    }

    .gpsAttendance-list {
        li {
            font-size: 16px;

            img {
                padding: 8px 7px;
            }
        }
    }


    .innerTitle {
        font-size: 4rem;
    }

    .reportSection {
        .crmService {
            display: block;

            .crmServiceContent {
                max-width: 400px;
                width: 100%;
                margin: 0 auto 80px auto;

                &:last-child {
                    margin-bottom: 0;
                }
            }

        }

        .titleContent {
            .reportTitle {
                font-size: 3rem;
            }
        }
    }

    .gpsAttendance {
        .title {
            font-size: 3rem;
        }
    }

    .reportSection {
        .crmService {
            .crmServiceContentImage {
                max-width: 45rem;
            }
        }
    }

    .featuresOfField {
        .fieldInspectionContent {
            h2 {
                font-size: 2rem;
            }
        }
    }

    .demoForm {
        .forCitizens {
            h2 {
                font-size: 3rem;
            }

            .requiredTwo {
                left: 0;
            }
        }

        .CitizensDotsBottom,
        .CitizensDots {
            display: none;
        }

    }
}

@media (max-width: @screen-sm) {
    .subTitle {
        &::after {
            width: 50px;
            right: -6rem;
        }
    }

    .demoForm {
        .contactForm {
            .nameInput {
                flex-wrap: wrap;
                gap: 0;

                .ant-form-item {
                    max-width: 100%;
                }
            }
        }
    }

    .innerTitle {
        font-size: 2.2rem;
    }

    .CityImg {
        bottom: 0;
    }

    .reportSection {
        margin-top: 5rem;

        .container {
            padding: 0;
        }

        .titleContent {
            .reportTitle {
                font-size: 2.2rem;
            }
        }

        .squreDotsRight,
        .squreDotsLeft {
            display: none;
        }
    }



    .demoForm {
        .crmServiceTitle {
            font-size: 2rem;
        }

        .crmServiceList {
            font-size: 1.4rem;
        }

        .forCitizens {
            transform: translateY(8rem);
            padding: 5rem 0;

            .subTitle {
                font-size: 1.8rem;
            }

            h2 {
                font-size: 2rem;
            }
        }
    }
}