@import "../variables.less";

// SystemModule
#SystemModule {
  text-align: center;
  padding: 60px 20px 140px;
  .container {
    max-width: 1700px;
  }
  .moduleList {
    padding: 0 0;
    background-color: transparent;
    .slick-track {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .slick-slide {
      margin: 16px;
      padding: 80px 0 0;
      position: relative;
      transition: all 0.5s ease-in-out;
      height: 100%;
      &::after {
        content: "";
        width: 0%;
        height: 7px;
        border-radius: 7px;
        background-color: @primary-color;
        position: absolute;
        left: 50%;
        bottom: 0;
        opacity: 0;

        transition: all 0.5s ease-in-out;
      }
      &:is(:hover, :focus-within, :focus-visible, :focus) {
        padding-top: 20px;
        &::after {
          opacity: 1;
          width: 100%;
          left: 0;
        }
      }
    }
    .slick-dots {
      width: 100%;
      max-width: 1200px;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      padding: 0;
      margin: 0 auto;
      display: flex !important;
      align-items: center;
      justify-content: center;
      // background-color: fade(@action_btn_color, 10%);
      border-radius: 7px;
      li {
        width: 100%;
        height: 2px;
        margin: 0;
        button {
          padding: 0;
          margin: 0;
          width: 100%;
          &:before {
            width: 100%;
            height: 2px;
            background-color: fade(@action_btn_color, 10%);
            opacity: 1;
            content: "";
          }
        }
        &:first-child {
          button:before {
            border-radius: 7px 0 0 7px;
          }
        }
        &:last-child {
          button:before {
            border-radius: 0 7px 7px 0;
          }
        }
        &.slick-active {
          button:before {
            border-radius: 7px;
            height: 5px;
            margin: -2px 0 0;
            background-color: @primary-color;
            opacity: 1;
          }
        }
      }
    }
    .moduleItem {
      display: flex !important;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 15px;
      text-align: left;
      box-shadow: 0px 4px 44px 0px fade(@black, 6%);
      padding: 45px 30px;
      border-radius: 10px;
      background-color: @white;
      .icon {
        position: relative;
        svg {
          width: 50px;
          height: 50px;
          path {
            fill: @primary-color;
          }
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          z-index: 1;
          top: -25%;
          right: -25%;
          width: 50px;
          height: 50px;
          border-radius: 50px;
          background-color: fade(@secondary-color, 20%);
        }
      }
      .listTitle {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.4;
        color: @black;
        margin: 0;
        &::after {
          content: "";
          display: block;
          width: 44px;
          height: 4px;
          border-radius: 6px;
          background-color: @primary-color;
          margin-top: 12px;
        }
      }
      .listDetails {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.4;
        color: fade(@text_light_black, 90%);
      }
      .link {
        font-size: 1.7rem;
        font-weight: 500;
        line-height: 1.4;
        text-transform: capitalize;
        color: @primary-color;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        svg {
          width: 11px;
          height: 11px;
          path {
            fill: @primary-color;
          }
        }
        &:is(:hover, :focus-within) {
          color: @secondary-dark-color;
          svg path {
            fill: @secondary-dark-color;
          }
        }
      }
      &:is(:hover, :focus-within) {
        .link {
          color: @secondary-dark-color;
          svg path {
            fill: @secondary-dark-color;
          }
        }
      }
    }
  }
  @media (max-width: @screen-sm) {
    padding: 40px 15px 0 15px;
    .container{
      padding: 0 1rem;
    }
    .moduleList{
      .moduleItem{
        padding: 2rem;
      }
      .slick-slide{
        padding: 1.5rem 0; 
      }
    }
  }
}
