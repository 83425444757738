@import "../variables.less";


#FieldInsPectionModuleInner {
  @import "../modules/requestDemo.less";
  .dotsImg {
    position: absolute;
}
  .middelSection {
    padding-top: 50px;
    position: relative;
    padding-bottom: 350px;

    .citybg {
      position: absolute;
      bottom: 0;
    }

    .middelSectionInner {
      position: relative;
      padding: 50px 0;
      z-index: 9;

      &:nth-child(even) {
        &::after {
          background: rgba(36, 144, 235, 10%);
          filter: blur(40px);
          position: absolute;
          height: 100%;
          width: 100%;
          content: "";
          bottom: 0;
          left: 0;
        }

        .projectListInner {
          order: 2;
          text-align: right;

          .title {
            padding: 0 0 46px 0;
            text-align: right;

            &::after {
              position: absolute;
              right: 0;
            }
          }

          .description {
            text-align: right;
          }

        }
      }

      .projectList {
        display: flex;
        gap: 70px;
        padding: 23px 0;
        position: relative;
        z-index: 99;
        &.FieldInsPectionModuleInnerImg{
          align-items: center;
          .projectImgInner{
            height: auto;
            width: auto;
            background-color: transparent;
            border-radius: none;

          }
        }
      }
    }
  }

  .container {
    max-width: 1240px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
  }

  .projectListInner {
    max-width: %;
    width: 100%;
    margin-top: 14px;

    .title {
      font-size: 44px;
      font-weight: 600;
      color: #0c1620;
      line-height: 62px;
      position: relative;
      text-transform: capitalize;
      margin: 0;
      padding: 0 0 30px 0;

      &::after {
        background-color: #2490eb;
        border-radius: 6px;
        content: "";
        display: block;
        height: 2px;
        margin-top: 15px;
        width: 52px;
      }
    }

    .description {
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      color: #545454;
      padding: 0;
      margin: 0;
      text-transform: capitalize;
    }
  }

  .projectImgInner {
    background: #2490eb;
    height: 233px;
    width: 224px;
    border-radius: 0% 50% 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.projectImgInnerIconBg{
      border-radius: 50%;
      background: linear-gradient(0deg, rgba(180, 221, 255, 0) 0%, rgba(172, 229, 46, 0.4) 100%);
    }
  }

  @media (max-width: @screen-xl) {
    .middelSection {
      padding-bottom: 200px;
    }
  }

  @media (max-width: @screen-lg) {
    .middelSection {
      .middelSectionInner {

        .projectListInner {
          .title {
            font-size: 40px;
            line-height: 46px;
          }
        }

        .projectList {
          gap: 20px;
        }
      }
    }

    .middelSection {
      padding-bottom: 120px;
    }
  }

  @media (max-width: @screen-md) {
    .middelSection {
      .FieldInsPectionModuleInnerImg{
        .projectImg{
          margin: 0 auto;
        }
        .projectListInner{
          order: 0 !important;
        }
        .projectImgInner{
       
          border-radius: none;
          height: auto;
          width: auto;
          img{
            width: 100%;
          }
        }
      }
      .projectImgInner{
        border-radius: 50%;
        height: 120px;
        width: 120px;
        img{
          width: 50px;
        }
      }
      .middelSectionInner {
        padding: 10px 0;
        &:last-child {
          .projectList{
            border: none;
          }
        }
        &:nth-child(even) {
          .projectListInner {
            text-align: left;

            .title,
            .description {
              text-align: left;

              &::after {
                right: auto;
                left: 0;
              }
            }
          }

        }

        .projectListInner {
          margin-bottom: 20px;
          order: 2;
          width: 100%;

          .title {
            font-size: 30px;
            line-height: 36px;
          }
        }

        .projectList {
          flex-wrap: wrap;
          gap: 0;
          border-bottom: dotted 1px #0002;
        
        }
      }
    }
  }

}