@import "../main.less";

// Banner
#banner {
  padding: 35px 20px;
  position: relative;
  z-index: 0;

  .Dotted_1 {
    position: absolute;
    top: 25%;
    left: -10px;
    transform: translateY(-50%);
    animation: diving-rotate 4s ease-in-out infinite;
  }

  .container {
    max-width: 1400px;
    display: grid;
    place-items: center;
    height: 100%;
    position: relative;
  }

  &::after {
    content: "";
    display: block;
    width: 65dvw;
    height: 65dvw;
    border-radius: 65dvw;
    background-color: fade(@primary-light-color, 30%);
    position: absolute;
    top: -30dvw;
    right: -15dvw;
    z-index: -1;
  }

  .imageSection {
    position: relative;
    z-index: 0;
    // isolation: isolate;
    text-align: center;

    .bannerImg {
      object-fit: contain;
      object-position: center;
      width: auto;
      max-height: 600px;
      position: relative;
      z-index: 1;
    }
  }

  .contentSection {
    margin-top: 30px;

    .mainHeading {
      font-size: 4.8rem;
      font-weight: 600;
      line-height: 1.3;
      padding: 0 30px 0 0;
      margin: 10px 0;
      word-break: keep-all;
      background: linear-gradient(180deg, #79c423 0%, #2490eb 36.83%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      color: @text_dark_black;
      font-size: 1.6rem;
      line-height: 1.7;
      letter-spacing: 0.032em;
      padding: 0 0 0 25px;
      margin: 20px 0 60px;
      width: 100%;
      max-width: 550px;
      border-left: 2px solid @primary-color;
    }

    .buttonLink {
      padding: 1rem 2.5rem;
      margin: 0 30px 0 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      border-radius: 60px;
      border: 1px solid @primary-color;
      background-color: @primary-color;
      color: @white;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.4;
      height: auto;
      box-shadow: 0px 4px 24px 0px @primary-light-color;

      svg {
        width: 12px;
        height: 12px;

        path {
          fill: @white;
        }
      }

      &:last-child {
        background-color: transparent;
        border-color: fade(@black, 30%);
        color: @text_light_black;
        box-shadow: none;

        svg path {
          fill: @text_light_black;
        }
      }

      &:is(:hover, :focus-within) {
        background-color: @secondary-color;
        border-color: @secondary-color;
        color: @white;

        svg path {
          fill: @white;
        }
      }
    }
  }

  @media (max-width: @screen-lg) {
    .container {
      padding: 0 10px;
    }

    .Dotted_1 {
      top: 3%;
    }

    .contentSection {
      .mainHeading {
        font-size: 2.8rem;
        font-weight: 800;
        line-height: 1.4;
        letter-spacing: 0.032rem;
        padding: 0;
        margin: 0;
      }

      .buttonLink {
        margin: 0 20px 20px 0;
      }
    }
  }

  @media (max-width: @screen-sm) {
    padding: 35px 20px 0px 20px;

    .container {
      padding: 0;
    }

    .contentSection {
      .mainHeading {
        font-size: 2rem;
        font-weight: 800;
        line-height: 1.4;
        letter-spacing: 0.032rem;
        padding: 0;
        margin: 0;
      }

      p {
        padding: 0 0 0 12px;
        margin: 1.5rem 0;
      }
    }
  }
}

// LogoSlider
#LogoSlider {
  padding: 80px 50px;

  .logoList {
    .slick-slide {
      padding: 12px;
    }

    .logoItem {
      display: block;
      padding: 0px;
      margin: 0;
      border-radius: 42px;
      box-shadow: 0px 4px 29px 0px rgba(0, 0, 0, 0.12);
    }
  }

  @media (max-width: @screen-md) {
    padding: 40px 10px;
    margin: -50px 0 50px;
  }

  @media (max-width: @screen-sm) {
    padding: 25px 0 0 0;
    margin: 0;

    .container {
      padding: 0;
    }
  }
}

// About
#About {
  position: relative;
  padding: 40px 0;

  .drone {
    position: absolute;
    top: -80px;
    left: 20px;
    transform: translateY(-50%);
    animation: diving-rotate 4s ease-in-out infinite;
  }

  .container {
    max-width: 1200px;
  }

  .contentSection {
    position: relative;
    z-index: 1;

    p {
      color: @text_dark_black;
      font-size: 1.6rem;
      line-height: 1.6;
      letter-spacing: 0.032em;
      padding-right: 50px;
    }

    .subTitleSection {
      color: @titleColor;
      font-size: 2rem;
      line-height: 1.5;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      margin: 26px 0;

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: @primary-color;
        }
      }

      &::after {
        content: "";
        width: 70px;
        height: 7px;
        border-radius: 7px;
        background-color: @primary-color;
        display: inline-block;
        margin-left: 15px;
      }
    }

    .certificateList {
      margin-bottom: 60px;
      max-width: 90%;

      .slick-slide {
        padding: 15px;
      }

      .slick-dots {
        width: 100%;
        height: 7px;
        padding: 0;
        margin: 0;
        display: flex !important;
        align-items: center;
        justify-content: center;
        background-color: fade(@action_btn_color, 10%);
        border-radius: 7px;

        li {
          width: 100%;
          height: 7px;
          margin: 0;

          button {
            padding: 0;
            margin: 0;
            width: 100%;

            &:before {
              width: 100%;
              height: 7px;
              background-color: fade(@action_btn_color, 10%);
              opacity: 1;
              content: "";
            }
          }

          &:first-child {
            button:before {
              border-radius: 7px 0 0 7px;
            }
          }

          &:last-child {
            button:before {
              border-radius: 0 7px 7px 0;
            }
          }

          &.slick-active {
            button:before {
              border-radius: 7px;
              background-color: @primary-color;
              opacity: 1;
            }
          }
        }
      }

      .slideItem {
        width: 100px;
        border-radius: 100px;
        border: 1px solid @primary-color;
        background-color: @white;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  #counterSection {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 10;
    gap: 20px;

    .oneItem {
      grid-column: 1 / 2;
      grid-row: 2 / span 4;
    }

    .twoItem {
      grid-column: 2 / 3;
      grid-row: 1 / span 4;
    }

    .threeItem {
      grid-column: 1 / 2;
      grid-row: 6 / span 10;
    }

    .fourItem {
      grid-column: 2 / 3;
      grid-row: 5 / span 8;
    }

    .counter {
      width: 100%;
      border-radius: 10px;
      background-color: @white;
      box-shadow: 0px 4px 44px 0px fade(@black, 6%);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .details {
        padding: 50px 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 15px;

        svg {
          width: 70px;
          height: 70px;

          path {
            fill: @primary-color;
          }
        }

        .countUp {
          color: @black;
          font-size: 4.2rem;
          font-weight: 600;
          line-height: 1.4;
          display: block;
          width: 100%;
        }

        strong {
          display: block;
          font-size: 1.7rem;
          font-weight: 400;
          line-height: 1.4;
          color: @black;
          margin-top: -10px;
        }
      }

      &:after {
        content: "";
        width: 100%;
        height: 7px;
        border-radius: 7px;
        background-color: @primary-color;
      }

      &.secondary {
        .details svg path {
          fill: @secondary-color;
        }

        &:after {
          background-color: @secondary-color;
        }
      }
    }
  }

  .CivicImg {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 0;
    transform: translateY(-50%);
  }

  .circles {
    width: 400px;
    height: 400px;
    top: 50%;
    left: -200px;
    transform: translateY(-50%);
  }

  @media (max-width: @screen-lg) {
    .drone {
      width: 200px;
      top: -95px;
      right: 10px;
      left: auto;
    }

    .CivicImg {
      width: 40%;
      top: 40%;
    }

    .circles {
      width: 300px;
      height: 300px;
      left: -120px;
    }

    .contentSection {
      .certificateList {
        max-width: 600px;
      }
    }
  }

  @media (max-width: @screen-md) {
    .CivicImg {
      width: 200px;
      top: 30%;
    }

    .circles {
      top: 63%;
      width: 300px;
      height: 300px;
      left: -120px;
    }

    .contentSection {
      .certificateList {
        max-width: 300px;
      }
    }

    #counterSection {
      .counter {
        .details {
          padding: 20px;

          svg {
            width: 50px;
            height: 50px;
          }

          .countUp {
            color: @black;
            font-size: 2.8rem;
            font-weight: 800;
            line-height: 1.4;
            display: block;
            width: 100%;
          }

          strong {
            display: block;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.4;
            color: @black;
            margin-top: -10px;
          }
        }
      }
    }
  }

  @media (max-width: @screen-sm) {
    padding: 25px 0 0 0;
    #counterSection {
      margin-top: 2.5rem;
      .counter {
        .details {
          .countUp {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}