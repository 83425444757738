@import "../variables.less";

// Solution
#MediaCoverage {
  position: relative;
  z-index: 1;
  text-align: left;
  padding: 20px 0px 20px 20px;

  .container {
    max-width: 1400px;
  }

  .titleTag {
    margin-bottom: 60px;
  }

  .buttonLink {
    margin-top: 30px;
  }

  .sliderContainer {
    .slick-track {
      display: flex;
      align-items: center;
      padding: 30px 50px;
    }

    .slick-slide {
      margin: 0 10px;
      padding: 30px 0 0;
    }

    .slideItem {
      background-color: transparent;
      box-shadow: none;
      transition: all 0.5s ease-in-out;

      .ant-card-cover {
        position: relative;
        margin: 0 0 -40px;
        padding: 10px 20px;
        width: 100%;
        height: 250px;

        .date {
          position: absolute;
          top: 30px;
          right: 25px;
          display: inline-block;
          width: max-content;
          color: @white;
          background-color: @secondary-color;
          border-radius: 50px;
          padding: 0.5rem 1.2rem;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.2;
          transition: all 0.5s ease-in-out;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 10px;
        }
      }

      .ant-card-body {
        padding: 50px 30px 20px 40px;
        border-radius: 10px;
        background-color: @white;
        box-shadow: 0px 4px 44px 0px fade(@black, 0.6%);
        transition: all 0.5s ease-in-out;

        .ant-card-meta-title {
          font-size: 2rem;
          font-weight: 500;
          line-height: 1.4;
          color: @black;
          transition: all 0.5s ease-in-out;
        }

        .ant-card-meta-description {
          font-size: 1.6;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: 0.032em;
          color: @text_light_black;
          margin-bottom: 30px;
        }

        .link {
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.4;
          text-transform: capitalize;
          color: @primary-color;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;

          svg {
            width: 10px;
            height: 10px;

            path {
              fill: @primary-color;
            }
          }

          &:is(:hover, :focus-within) {
            color: @secondary-dark-color;

            svg path {
              fill: @secondary-dark-color;
            }
          }
        }
      }

      &:is(:hover, :focus-within) {
        transform: translateY(-20px);

        .ant-card-cover {
          .date {
            background-color: @primary-color;
          }
        }

        .ant-card-body {
          box-shadow: 0px 30px 30px -10px fade(@black, 15%);

          .ant-card-meta-title {
            a {

              color: @primary-color;
            }
          }

          .link {
            color: @secondary-dark-color;

            svg path {
              fill: @secondary-dark-color;
            }
          }
        }
      }
    }

    .slick-dots {
      width: 100%;
      max-width: 1200px;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      padding: 0;
      margin: 0 auto;
      display: flex !important;
      align-items: center;
      justify-content: center;
      // background-color: fade(@action_btn_color, 10%);
      border-radius: 7px;

      li {
        width: 100%;
        height: 2px;
        margin: 0;

        button {
          padding: 0;
          margin: 0;
          width: 100%;

          &:before {
            width: 100%;
            height: 2px;
            background-color: fade(@action_btn_color, 10%);
            opacity: 1;
            content: "";
          }
        }

        &:first-child {
          button:before {
            border-radius: 7px 0 0 7px;
          }
        }

        &:last-child {
          button:before {
            border-radius: 0 7px 7px 0;
          }
        }

        &.slick-active {
          button:before {
            border-radius: 7px;
            height: 5px;
            margin: -2px 0 0;
            background-color: @primary-color;
            opacity: 1;
          }
        }
      }
    }
  }
  @media (max-width:1700px) {
    .sliderContainer {
      .slick-track{
        padding: 30px 20px;
      }
    }
  }
  @media (max-width:1200px) {
    .sliderContainer {
      .slideItem {
        .ant-card-cover{
          height: 200px;
        }
      }
    }
  }
  @media (max-width:992px) {
    .sliderContainer {
      .slick-track{
        padding: 20px 0;
      }
  
    }
  }
  @media (max-width:@screen-md) {
    .sliderContainer {
      .slick-track {
        padding: 30px 0;
      }
      .slideItem {
        .ant-card-cover{
          height: auto;
        }
      }
    }
  }
  @media (max-width:@screen-sm) {
    padding: 15px;
    .titleTag{
      margin: 0;
    }
    .buttonLink{
      margin: 0;
    }
    .sliderContainer{
      .slick-track{
        padding: 20px 0 10px 0;
      }
      .slideItem{
        .ant-card-body{
          padding: 40px 10px 10px 10px; 
        }
      }
    }
  }
}