@import "../variables.less";

// Solution
#Solution {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 60px 0px 20px;
  overflow: hidden;

  .CityImg {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .steps {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 40px 0;

    .step {
      margin-top: 100px;
      transition: all 0.5s ease-in-out;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;

      .stepNo,
      .title {
        display: block;
        font-size: 4.8rem;
        font-weight: 600;
        line-height: 1;
        color: @text_dark_black;
        margin: 0;
        padding: 0;
      }

      .title {
        font-size: 1.6rem;
        color: @primary-color;
      }

      img {
        background: #2490eb;
        background: linear-gradient(180deg,
            #2490eb 0%,
            #58cdea 99.99%,
            #79c423 100%);
        border-radius: 50%;
        padding: 10px;
        width: 210px;
        height: 210px;
      }

      &.Step2 {
        margin-top: -100px;
      }

      &:is(:hover, :focus-within) {
        transform: translateY(-10px);
      }
    }

    .LineImg {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      z-index: -1;
      scale: 1;
    }
  }

  @media (max-width: @screen-lg) {
    .steps {
      flex-direction: column;
      gap: 0;

      .step,
      .step.Step2 {
        margin: 0 0 30px;
        gap: 5px;
      }

      img {
        scale: 0.8;
        margin: 0;
      }

      .stepNo {
        display: block;
      }

      .LineImg {
        transform: rotate(90deg);
        scale: 1;
        margin-top: -60px;
      }
    }

    .CityImg {
      scale: 2;
      bottom: 100px;
      left: 50%;
      transform: translateX(-25%);
    }
  }

  @media (max-width: @screen-md) {
    .steps {
      .LineImg {
        transform: rotate(90deg);

        margin-top: -60px;
      }
    }

    .CityImg {
      scale: 6;
    }
  }

  @media (max-width:@screen-sm) {


    padding: 40px 15px 20px 15px;

    .steps {
      .LineImg {

        scale: 1.6;
        margin-top: -60px;

      }
    }
  }

}