@import "../main.less";


#contact {
  padding: 0;
  position: relative;
  z-index: 0;
  .banner {
    width: 100%;
    padding: 60px 0;
    margin: 0 0 150px;
    background-color: #f0f8ff;
    .title {
      color: @black;
      font-size: 52px;
      font-weight: 600;
      line-height: normal;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
      &::after {
        content: "";
        display: inline-block;
        width: 80px;
        height: 3px;
        background-color: #79c423;
      }
      &::before {
        display: none;
      }
    }
    svg {
      width: 70%;
      height: auto;
      margin: 0 auto;
    }
    @media (max-width: @screen-lg) {
      padding: 60px 0 0px;
      margin: 20px auto;
      .title {
        font-size: 30px;
      }
    }
  }

  .drone {
    position: absolute;
    top: -80px;
    left: 20px;
    transform: translateY(-50%);
    animation: diving-rotate 4s ease-in-out infinite;
  }

  .container {
    max-width: 1400px;
    display: grid;
    place-items: center;
    height: 100%;
    position: relative;
  }

  .contactForm {
    border-radius: 15px;
    background-color: #f0f8ff;
    padding: 40px 40px 60px;
    .title {
      color: @black;
      font-size: 3.2rem;
      font-weight: 300;
      line-height: normal;
      margin: 0;
      padding: 0;
    }
    .note {
      color: rgba(0, 0, 0, 0.7);
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0 ;
      padding: 0;
    }
    input,
    textarea {
      width: 100%;
      height: auto;
      background-color: @white;
      border-radius: 12px;
      padding: 1rem 1.5rem;
      color: @black;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.3;
      resize: none;
    }
    label {
      color: #605746;
      font-size: 1.36rem;
      font-weight: 600;
      line-height: normal;
    }
    .captchaLeft {
      background-color: @white;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 24px;
      .captchaCode {
        margin: 0;
      }
    }
    #re_captcha {
      background-color: transparent;
      border-bottom: 1px solid #2490eb;
      border-radius: 0;
      &::placeholder {
        color: #605746;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: normal;
      }
    }
    .submit {
      padding: 0.8rem 1.5rem;
      margin-bottom: 24px;
      width: 100%;
      height: auto;
      border-radius: 10px;
      background-color: #2490eb;
    }
    .helpLink{
      color: @primary-color;
      &:hover{
        color: @secondary-color;
      }
    }
  }

  .section {
    color: #605746;
    font-size: 37px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    &::after {
      content: "";
      display: inline-block;
      width: 80px;
      height: 3px;
      background-color: #79c423;
    }
  }

  .contactDetails {
    &::after {
      content: "";
      display: block;
      border-radius: 10px;
      background-color: #2490eb;
      box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.06);
      height: 7px;
      margin-top: 60px;
    }
    .title {
      font-size: 30px;
      font-weight: 600;
      line-height: 58px; /* 193.333% */
      text-transform: capitalize;
      color: #2490eb;
      margin: 0;
      padding: 0;
    }

    .address {
      color: #565969;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px; /* 123.81% */
      letter-spacing: 0.42px;
      text-transform: capitalize;
      width: 100%;
      padding: 0;
      display: inline-block;
      margin: 20px 0 0px;
      &:hover{color: #69b1ff;

      }
    }
    hr {
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      border: none;
      padding: 0;
      margin: 40px 0;
    }

    .iconList {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      margin-bottom: 30px;
      .icon {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        border: 1px solid #d9d9d9;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease-in-out;
        svg {
          width: 20px;
          height: 20px;
          path {
            fill: @secondary-color;
            transition: all 0.5s ease-in-out;
          }
        }
      }
      .details {
        width: calc(100% - 90px);
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        strong {
          color: #565969;
          font-size: 18px;
          font-weight: 400;
          line-height: 19px; /* 105.556% */
          letter-spacing: 0.36px;
          text-transform: capitalize;
          margin-bottom: 8px;
        }
        a {
          color: #565969;
          font-size: 26px;
          font-weight: 400;
          line-height: 26px; /* 100% */
          letter-spacing: 0.52px;
          text-transform: capitalize;
          transition: all 0.5s ease-in-out;
        }
      }

      &:is(:hover, :focus-within) {
        .icon {
          background-color: @primary-color;
          svg path {
            fill: @white;
          }
        }
        .details {
          a {
            color: @primary-color;
          }
        }
      }
    }
    @media (max-width: @screen-sm) {
      .title{
        font-size: 2rem;
      }
      .section{
         font-size: 2.1rem;
      }
      .address{
        font-size: 1.6rem;
      }
      .iconList{
        margin-bottom: 15px;
        .details{
          a{
            font-size: 1.9rem;
          }
        }
      }
    }
  }

  .supportDetails {
    .box {
      border-radius: 14px;
      border: 1px solid #d9d9d9;
      padding: 34px;
      margin: 20px 0 40px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .list {
        padding: 15px 20px;
        strong {
          color: #605746;
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
          display: block;
          margin-bottom: 5px;
        }
        a {
          color: #565969;
          font-size: 26px;
          font-weight: 400;
          line-height: 26px; /* 100% */
          letter-spacing: 0.52px;
        }
        &:is(:hover, :focus-within) {
          strong {
            color: @secondary-dark-color;
          }
          a {
            color: @primary-color;
          }
        }
      }
      @media (max-width: @screen-xl) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: @screen-lg) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: @screen-md) {
        padding: 10px;
        .list {
          padding: 10px;
          strong {
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 8px;
          }
          a {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
