* {
  scrollbar-color: #b5b8bb #e9e9e9;
  scrollbar-width: thin;
  font-family: @primary-font !important;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #e9e9e9;
  }

  ::-webkit-scrollbar-thumb {
    background: #b5b8bb;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: #93999e;
    }
  }
}

html,
body {
  font-size: 62.5%;
}

html body {
  font-family: @primary-font !important;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.4;
  color: @text_dark_black;
  margin: 0px;
  padding: 0px;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

img {
  max-width: 100%;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
a {
  color: @text-color;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  img,
  svg {
    transition: all 0.5s ease-in-out;
    path {
      transition: all 0.5s ease-in-out;
    }
  }
}

::-webkit-input-placeholder {
  /* Edge */
  color: rgba(0, 0, 0, 0.6);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.6);
}

::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.fullscreen__spinner__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  .ant-spin-dot-item {
    background-color: @secondary-color;
  }
}

