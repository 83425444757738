@import "../variables.less";

#BlogPage {
    background-color: @white;
    padding-bottom: 5rem;

    .blogBanner {
        padding: 18rem;
        width: 100%;
        margin: 0 auto;

        h2 {
            width: 100%;
            margin: 0 auto;
            color: @primary-color;
            font-size: 7.2rem;
            line-height: normal;
            font-weight: @font-weight-600;
            position: relative;

            &:after {
                position: absolute;
                content: "";
                height: 0.5rem;
                width: 10rem;
                left: 0;
                top: 0;
                background-color: @primary-color;
            }
        }
    }

    .container {
        max-width: 1600px;
    }

    .slideItem {
        background-color: transparent;
        box-shadow: none;
        transition: all 0.5s ease-in-out;
        min-height: 49rem;


        .ant-card-cover {
            position: relative;
            margin: 0 0 -40px;
            padding: 1rem 2rem;
            width: 100%;
            height: auto;
            background-color: transparent;
            box-shadow: none;



            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 2rem;
            }

            .date {
                position: absolute;
                top: 30px;
                right: 25px;
                display: inline-block;
                width: max-content;
                color: @white;
                background-color: @secondary-color;
                border-radius: 50px;
                padding: 0.5rem 1.2rem;
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 1.2;
                transition: all 0.5s ease-in-out;
            }
        }

        .ant-card-body {
            padding: 60px 30px 40px;
            border-radius: 10px;
            background-color: @white;
            box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.06);
            border: 1px solid #f2f2f2;
            transition: all 0.5s ease-in-out;

            .ant-card-meta-title {
                font-size: 2rem;
                font-weight: 500;
                line-height: 1.4;
                color: @black;
                transition: all 0.5s ease-in-out;
            }

            .ant-card-meta-description {
                font-weight: 400;
                line-height: 1.3;
                letter-spacing: 0.032em;
                color: @text_light_black;
                margin-bottom: 30px;
            }

            .link {
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 1.4;
                text-transform: capitalize;
                color: @primary-color;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
            }
        }

        &:is(:hover, :focus-within) {
            transform: translateY(-20px);

            .date {
                background-color: @primary-color;
            }

            .ant-card-meta-title {
                a {
                    color: @primary-color
                }
            }

            .link {
                color: @secondary-color;
            }

            svg {
                path {

                    fill: @secondary-color ;
                }
            }

            .ant-card-body {
                box-shadow: 0px 30px 30px -10px rgba(0, 0, 0, 0.15);
            }
        }


    }

    .ant-pagination {
        width: 100%;
        text-align: center;
    }

    @media (max-width: @screen-md) {
        .container {
            padding: 0 10px;
        }
    }
}