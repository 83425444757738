@import "../variables.less";

#topBenefits {
    position: relative;
    margin-bottom: 10rem;

    .dotsImg,
    .rightCircle,
    .greenStar,
    .whiteBottomStar,
    .rightStar,
    .bottomCircle {
        position: absolute;
    }

    .ant-tabs-ink-bar {
        display: none;
    }

    .rightCircle {
        right: 0;
        top: 6rem;
    }

    .greenStar {
        left: 13%;
        top: 20rem;

        svg {
            fill: #6DD8CB;
            color: #6DD8CB;
        }
    }

    .slick-dots {
        width: 100%;
        max-width: 1200px;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        padding: 0;
        margin: 0 auto;
        display: flex !important;
        align-items: center;
        justify-content: center;
        // background-color: fade(@action_btn_color, 10%);
        border-radius: 7px;

        li {
            width: 100%;
            height: 2px;
            margin: 0;

            button {
                padding: 0;
                margin: 0;
                width: 100%;

                &:before {
                    width: 100%;
                    height: 2px;
                    background-color: fade(@action_btn_color, 10%);
                    opacity: 1;
                    content: "";
                }
            }

            &:first-child {
                button:before {
                    border-radius: 7px 0 0 7px;
                }
            }

            &:last-child {
                button:before {
                    border-radius: 0 7px 7px 0;
                }
            }

            &.slick-active {
                button:before {
                    border-radius: 7px;
                    height: 5px;
                    margin: -2px 0 0;
                    background-color: @primary-color;
                    opacity: 1;
                }
            }
        }
    }

    .whiteBottomStar {
        left: 15%;
        bottom: 20%;
        top: auto;
    }

    .rightStar {
        right: 10%;
        top: 20rem;
    }

    .bottomCircle {
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -7rem;
        z-index: 0;
    }

    .topBenefits-bg {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        padding: 6rem 0;
    }

    .topBenefitsContainer {
        max-width: 1230px;
        padding: 0 15px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }

    .titleSection {
        color: @white;
        font-size: 4.4rem;
        font-weight: 600;
        line-height: 1.5;
        margin: 0;
        padding: 0;
    }

    .upperContent {
        text-align: center;
        color: @white;
        margin-bottom: 4rem;

        p {
            line-height: 1.6;
            font-size: 1.6rem;
        }
    }

    .moduleList {

        .icon {
            position: relative;

            &::after {
                content: "";
                display: block;
                position: absolute;
                z-index: 1;
                top: -25%;
                right: -25%;
                width: 50px;
                height: 50px;
                border-radius: 50px;
                background-color: rgba(113, 191, 68, 0.2);
            }

            svg {
                height: 4rem;
                width: 3.6rem;
            }
        }

        .testimonialItem {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            gap: 15px;
            text-align: left;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 15px;
            border-radius: 10px;
            background-color: @white; // Make sure @white is defined
            margin: 2rem 1rem;
            padding: 2.5rem 2rem;
            position: relative;

            &:hover {
                &::after {
                    opacity: 1;
                    width: 100%;
                    left: 0;
                }
            }

            &::after {
                content: "";
                width: 0;
                height: 7px;
                border-radius: 7px;
                background-color: #2490eb;
                position: absolute;
                left: 50%;
                bottom: 0;
                opacity: 0;
                transition: all 0.5s ease-in-out;
            }
        }

        .title {
            font-size: 1.8rem;
            font-weight: 500;
            line-height: 1.4;
            color: @black;
            margin: 0;

            &::after {
                content: "";
                display: block;
                width: 44px;
                height: 4px;
                border-radius: 6px;
                background-color: #2490eb;
                margin-top: 12px;
            }
        }

        p {
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 2;
            color: rgba(86, 89, 105, 0.9);
        }


    }

    .ant-tabs-content-holder {
        background: @white;
        padding: 5.5rem 2rem;
        border-radius: 2rem;
    }

    .ant-tabs-nav {
        margin: 0;

    }

    .ant-tabs-nav {
        &::before {
            border: 0 !important;
        }
    }


    .ant-tabs-nav-list {
        margin: 0 auto;

        .ant-tabs-tab {
            background: #d9d9d9;
            padding: 15px 60px;
            border-radius: 20px 20px 0px 0px;
            letter-spacing: 1px;
            font-weight: 600;

        }

        .ant-tabs-tab-active {
            border: 0 !important;
            background-color: @white;
        }
    }

    .ant-tabs-tab-btn {
        font-size: 16px;
    }



    @media (max-width:992px) {
        .titleSection {
            font-size: 2.8rem;
            font-weight: 800;
            line-height: 1.3;
            letter-spacing: 0.032em;
            margin-top: 10px;
        }

        .greenStar,
        .whiteBottomStar,
        .rightStar {
            display: none;
        }

        .ant-tabs-content-holder {

            border-radius: 2rem;
        }
    }

    @media (max-width:@screen-lg) {
        .bottomCircle {
            bottom: -4rem;
        }

        .dotsImg,
        .rightCircle {
            display: none;
        }

        .titleSection {
            font-size: 1.7rem;
            margin: 1rem 0;

        }

        .ant-tabs-nav-list .ant-tabs-tab {
            padding: 12px 20px;
            border-radius: 10px 10px 0px 0px;
        }

        .ant-tabs-tab {
            margin: 0 1rem;
        }

        .ant-tabs-tab-btn {
            font-size: 14px;
        }

    }

    @media (max-width:@screen-sm) {
        .titleSection {
            font-size: 1.7rem;
        }



    }

    @media (max-width: 480px) {
        .bottomCircle {
            bottom: -2rem;
        }


        .ant-tabs-nav-list .ant-tabs-tab {
            border-radius: 0;
            justify-content: center;
        }

    }

    @media (max-width: 400px) {
        .bottomCircle {
            bottom: -2rem;
        }

        .ant-tabs-nav-list {
            flex-direction: column;
        }

        .ant-tabs-nav-list .ant-tabs-tab {
            margin-bottom: 5px;
            border-radius: 0;
            justify-content: center;
        }

    }


}