@import "../variables.less";

// Testimonial
#Testimonial {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 100px 0;
  isolation: isolate;

  svg.qoute {
    width: 105px;
    height: 80px;
  }

  .sliderContainer {
    width: 80%;
    max-width: 1200px;
    min-height: 400px;
    margin: 0 auto;
    padding: 50px 0;
    background-repeat: no-repeat;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    .testimonialItem {
      text-align: center;
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;

      p {
        padding: 20px 8% 0;
        color: @text_dark_black;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 1.5;
      }

      .name {
        color: @primary-color;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.3;
      }

      .post {
        color: @text_dark_black;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: 0.032rem;
      }

      .photo {
        width: 90px;
        height: 90px;
        border-radius: 90px;
        display: block;
      }
    }

    .slick-prev,
    .slick-next {
      width: 42px;
      height: 42px;
      border-radius: 42px;
      border: 1px solid fade(@tagColor, 40%);
      background-color: @white;
      transition: all 0.5s ease-in-out;

      svg {
        width: 24px;
        height: auto;

        path {
          fill: #031b4e;
          transition: all 0.5s ease-in-out;
        }
      }

      &:is(:hover, :focus-within) {
        background-color: @primary-color;
        border-color: @primary-color;

        svg path {
          fill: @white;
        }
      }

      &:before,
      &:before {
        content: "";
      }
    }

    .slick-next {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .bubbles {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    .bubble {
      width: auto;
      height: auto;
      aspect-ratio: 1/1;
      border-radius: 50%;
      position: absolute;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      background-color: fade(@secondary-color, 20%);
      animation: bubbles 3s infinite linear;

      // opacity: 0;
      &.one {
        top: 10%;
        left: -20px;
        width: 87px;
        background-color: fade(@secondary-color, 20%);
        animation-delay: 1s;
      }

      &.two {
        top: 10%;
        right: -20px;
        width: 49px;
        background-color: fade(@primary-color, 20%);
        animation-delay: 2s;
      }

      &.three {
        bottom: 20%;
        right: 50px;
        width: 42px;
        background-color: fade(@secondary-color, 20%);
        animation-delay: 3s;
      }

      &.four {
        bottom: 20%;
        left: 25%;
        width: 20px;
        background-color: fade(@primary-color, 20%);
        animation-delay: 4s;
      }

      &.five {
        bottom: 10%;
        left: 45%;
        width: 25px;
        background-color: fade(@secondary-color, 20%);
        animation-delay: 5s;
      }
    }
  }

  @media (max-width: @screen-lg) {
    .sliderContainer {
      .testimonialItem {
        padding: 20px 10px;

        p {
          font-size: 1.7rem;
          font-weight: 600;
          line-height: 1.8;
          letter-spacing: 0.032em;
        }
      }
    }
  }

  @media (max-width: @screen-sm) {
    padding: 25px 0;

    svg.qoute {
      height: 40px;
    }


    .sliderContainer {
      padding: 40px 0 20px 0;
      .testimonialItem {
        padding: 0px;

        p {
          font-size: 1.5rem
        }
      }
    }
  }
}

@import "../keyframes.less";