@import "../variables.less";

// Work
#Work {
  position: relative;
  isolation: isolate;
  .title {
    text-align: center;
    width: 95%;
    margin: 0 auto 50px;
    padding: 0;
    font-size: 8rem;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    color: fade(@tagColor, 20%);
  }

  .slider-bg {
    background-color: transparent;
    position: relative;
    padding: 100px 0 50px;
    margin: 130px auto 0;
    width: 100%;
    .img1 {
      position: absolute;
      left: 0;
      top: 1rem;
      z-index: 0;
      width: 27%;
    }
    .img2 {
      position: absolute;
      right: 0;
      top: 4rem;
      z-index: 0;
      width: 27%;
    }
    .sliderContainer {
      max-width: 1200px;
      width: 80%;
      margin: auto;
    }
    .slick-prev {
      left: auto;
      right: 55px;
      z-index: 9;
    }
    .slick-next {
      left: auto;
      right: 0;
    }

    .slick-prev,
    .slick-next {
      top: -25px;
      width: 42px;
      height: 42px;
      border-radius: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #d4d4d4;
      padding: 11px;
      &:hover {
        background: @primary-color;
        path {
          stroke: #fff;
        }
      }
    }
    .slick-slider {
      margin-top: -188px;
      z-index: 5;
    }
    .slick-list {
      padding-bottom: 0px !important;
      padding-top: 0px !important;
      position: relative;
    }

    .slick-slide {
      transform: scale(0.5) translate(143%) translateY(0%);
      position: relative;
      transition: all 0.5s ease;
      padding: 0;
      img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
      }
    }

    .slick-slide.slick-active {
      transform: scale(0.7) translate(35%) translateY(0%);
      transition: all 0.5s ease;
      z-index: 1;
      box-shadow: -75px 0px 92px -20px rgba(77, 77, 77, 0.55);
    }

    .slick-slide.slick-center + .slick-slide {
      transform: scale(0.7) translate(-35%) translateY(0%);
      transition: all 0.5s ease;
      z-index: 1;
      box-shadow: 75px 0px 92px -20px rgba(77, 77, 77, 0.55);
    }

    .slick-slide.slick-center + .slick-slide + .slick-slide {
      transform: scale(0.5) translate(-143%) translateY(-1%);
      position: relative;
      transition: all 0.5s ease;
    }

    .slick-slide.slick-center {
      transform: scale(0.95);
      z-index: 30;
      transition: all 0.5s ease;
      padding: 0;
      box-shadow: -45px 0px 60px -40px rgba(77, 77, 77, 0.55),
        45px 0px 60px -40px rgba(77, 77, 77, 0.55);
      border-radius: 80px;
      img {
        width: 296px;
        height: auto;
        transform: scale(0.95);
        border-radius: 30px;
      }
      &:after {
        content: "";
        height: 100%;
        width: 100%;
        position: fixed;
        bottom: 0;
        top: 0;
        background-image: url("../../images/slider/mobile-frame.png");
        background-position: top;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: transparent;
        // border-radius: 60px;
      }
    }

    @media (max-width: @screen-lg) {
      .sliderContainer {
        width: 90%;
      }
      .slick-slider {
        margin-top: -130px;
      }

      .slick-slide {
        transform: scale(0.65) translate(100%) translateY(0%);
      }
      .slick-slide.slick-center + .slick-slide + .slick-slide {
        transform: scale(0.65) translate(-100%) translateY(0%);
      }
      .slick-slide.slick-active {
        transform: scale(0.75) translate(30%) translateY(0%);
      }
      .slick-slide.slick-center + .slick-slide {
        transform: scale(0.75) translate(-30%) translateY(0%);
      }
      .slick-slide.slick-center {
        transform: scale(1);
        img {
          border-radius: 10px;
        }
      }
      .img1,
      .img2 {
        width: 30%;
      }
    }
    @media (max-width: @screen-md) {
      .slick-slider {
        margin-top: -130px;
      }

      .slick-slide {
        transform: scale(0.65) translate(100%) translateY(0%);
      }
      .slick-slide.slick-center + .slick-slide + .slick-slide {
        transform: scale(0.65) translate(-100%) translateY(0%);
      }
      .slick-slide.slick-active {
        transform: scale(0.75) translate(30%) translateY(0%);
      }
      .slick-slide.slick-center + .slick-slide {
        transform: scale(0.75) translate(-30%) translateY(0%);
      }
      .slick-slide.slick-center {
        transform: scale(1);
      }
    }
  }

  .circles {
    width: 200px;
    height: 200px;
    top: 30%;
    left: auto;
    right: -100px;
    transform: translateY(-50%);
    & > div {
      background-color: fade(@primary-color, 80%);
    }
  }

  &:after {
    content: "";
    display: block;
    width: 100dvw;
    height: 700px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 54%);
    background-color: @primary-light-color;
    position: absolute;
    top: 55%;
    left: 0;
    z-index: -1;
    opacity: 0.3;
  }
  @media (max-width: @screen-lg) {
    padding-top: 20px;
    .title {
      font-size: 6rem;
    }
    &:after {
      height: 250px;
      top: 50%;
    }
    .slider-bg {
      margin: 0;
    }
  }
  @media (max-width: @screen-md) {
    .title {
      font-size: 3rem;
    }
  }
  @media (max-width: @screen-sm) {
    .title {
      font-size: 1.9rem;
      margin: 50px 0 50px 0;
    }
  }
}
