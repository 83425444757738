.mainWrapper {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.mainContent {
  padding-top: 140px;

  @media (max-width: @screen-xxl) {
    padding-top: 120px;
  }

  @media (max-width: @screen-xl) {
    padding-top: 103px;
  }
}

.mainHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 140px;
  background-color: @white;
  box-shadow: 8px 4px 16px rgba(0, 0, 0, 0.08);

  .container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    position: relative;
  }

  .siteLogo {
    position: absolute;
    top: 0;
    left: 20px;
    z-index: 1;
    display: grid;
    place-items: center;
    width: 100%;
    max-width: 420px;
    height: auto;
    padding: 20px 80px 20px 20px;
    overflow: visible;
    isolation: isolate;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: calc(100% - 95px);
      height: 100%;
      background-color: #fff;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      z-index: -2;
      width: 0;
      height: 0;
      background-color: transparent;
      border-top: 140px solid #fff;
      border-right: 95px solid transparent;
      filter: drop-shadow(8px 4px 18px rgba(0, 0, 0, 0.15));
    }

    img {
      max-width: 300px;
      filter: unset;
    }
  }

  .headerNavSection {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding-left: 350px;

    .actionStrip {
      background-color: @lite_gray;
      padding-left: 100px;
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      isolation: isolate;
      position: relative;

      .contact {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 2.5;
          color: @text_dark_black;

          svg {
            width: auto;
            height: 1.3rem;
          }

          &:is(:hover, :focus-within) {
            color: @secondary-dark-color;

            svg path {
              fill: @secondary-dark-color;
            }
          }
        }
      }

      .social {
        display: flex;
        align-items: center;
        gap: 4rem;

        ul {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 1.5rem;

          li {
            display: flex;
            align-items: center;

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 2.6rem;
              height: 2.6rem;
              background-color: @white;
              border-radius: 50%;

              &:is(:hover, :focus-within) {
                background-color: @secondary-dark-color;

                svg {
                  path {
                    fill: @white;
                  }

                  &.stroke {
                    path {
                      stroke: @white;
                    }
                  }
                }
              }
            }
          }
        }

      }

      .helpAndSupport {
        a {
          color: @primary-color;
          display: flex;
          align-items: center;
        }

        svg {
          height: 1.1rem;
          margin-left: 0.5rem;
        }

        .helpIcon {
          height: 1.8rem;
          width: 1.8rem;
          margin-right: 1rem;
        }

        .active {
          display: flex;
          align-items: center;
          color: @secondary-color;

          svg {
            path {

              fill: @secondary-color ;

            }
          }
        }

        &:hover {
          a {
            color: @secondary-color;
          }

          svg {
            path {

              fill: @secondary-color ;

            }
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -95%;
        z-index: -1;
        display: block;
        width: 100%;
        height: 44px;
        background-color: @lite_gray;
      }
    }

    .menuStrip {
      background-color: @white;
      padding-left: 100px;
      width: 100%;
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .nav {
        display: grid;
        grid-template-columns: auto;
        grid-auto-flow: column;
        gap: 4.5rem;

        a {
          display: inline-block;
          font-size: 1.8rem;
          font-weight: 500;
          text-align: center;
          transition: all 500ms linear;
          white-space: nowrap;

          &:is(:hover, :focus-within, .active) {
            color: @secondary-color;
            font-weight: 600;
          }

          &:before {
            display: block;
            content: attr(title);
            font-weight: bold;
            height: 0;
            overflow: hidden;
            visibility: hidden;
            transition: all 500ms linear;
          }
        }
      }

      .actionBtns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 3rem;

        a,
        .buttonPrimary {
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 1.4;
          text-align: center;
          text-transform: capitalize;
          color: @primary-color;
          background-color: @white;
          border-radius: 60px;
          padding: 1rem 2.5rem;
          margin: 0;
          // display: inline-flex;
          // align-items: center;
          // justify-content: space-around;
          // gap: 5px;

          svg {
            margin-left: 0.8rem;
            width: auto;
            height: 1.1rem;

            path {
              fill: @primary-color;
            }
          }

          &:is(:hover, :focus-within) {
            // background-color: @secondary-dark-color;
            color: @secondary-dark-color;

            svg path {
              fill: @secondary-dark-color;
            }
          }
        }

        .buttonPrimary {
          color: @white;
          background-color: @primary-color;
          border-color: @primary-color;
          box-shadow: 0px 4px 24px 0px #b4ddff;
          height: auto;

          svg path {
            fill: @white;
          }

          &:is(:hover, :focus-within) {
            background-color: @secondary-dark-color;
            border-color: @secondary-dark-color;
            color: @white;

            svg path {
              fill: @white;
            }
          }
        }
      }
    }

    #hamburgerIcon {
      display: none;
    }
  }

  @media (max-width: @screen-xxl) {
    height: 120px;

    .siteLogo {
      max-width: 300px;
      padding: 27px 60px 28px 20px;

      img {
        max-width: 200px;
      }

      &::before {
        width: calc(100% - 60px);
      }

      &::after {
        border-top-width: 120px;
        border-right-width: 60px;
      }
    }

    .headerNavSection {
      padding-left: 205px;

      .menuStrip {
        height: 76px;

        .nav {
          gap: 2rem;

          a {
            font-size: 1.6rem;
          }
        }

        .actionBtns {
          gap: 1rem;

          a,
          .buttonPrimary {
            font-size: 1.6rem;
            padding: 1rem 2.5rem;
            white-space: nowrap;
          }
        }
      }
    }
  }

  @media (max-width: @screen-xl) {
    padding: 0;
    height: 103px;

    .container {
      padding: 0;
    }

    .siteLogo {
      max-width: 180px;
      padding: 27px 40px 27px 0;

      img {
        max-width: 140px;
      }

      &::before {
        width: calc(100% - 30px);
      }

      &::after {
        border-top-width: 105px;
        border-right-width: 30px;
      }
    }

    .headerNavSection {
      padding-left: 110px;
      padding-right: 25px;

      // .actionStrip {
      //   padding: 0 25px 0 105px;
      // }
      .menuStrip {
        height: 59px;
        // justify-content: space-between;
        gap: 1rem;

        .nav {
          gap: 2rem;

          a {
            font-size: 1.4rem;
          }
        }

        .actionBtns {
          gap: 1rem;

          a,
          .buttonPrimary {
            white-space: nowrap;
            font-size: 1.5rem;
            padding: 1rem 1rem;

            svg {
              margin-left: 1px;
            }
          }
        }
      }
      .actionStrip{
        .contact{
          a{

            font-size: 1.3rem;
          }
        }
      }
      .social{
        .helpAndSupport{
          a{
            font-size: 1.3rem;
          }
        }
      }
    }
  }

  @media (max-width: @screen-lg) {
    padding: 0;
    transition: all 0.3s ease-in-out;
    // position: relative;

    .siteLogo {
      max-width: 230px;
      padding: 10px 40px 10px 20px;
      margin-left: -20px;
      margin-top: 44px;

      img {
        max-width: 150px;
        object-fit: contain;
        object-position: center center;
      }

      &:after {
        border-top-width: 70px;
        border-right-width: 30px;
      }
    }

    .headerNavSection {
      padding: 0;
      align-items: flex-end;

      .actionStrip {
        padding: 0 20px;
        
        gap: 1.5rem;
        order: 1;

        .contact {
          gap: 1.5rem;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.6rem;
            height: 2.6rem;
            background-color: @white;
            border-radius: 50%;
            font-size: 1.3rem;
          }

          span {
            display: none;
          }
        }

        .social {
          gap: 1.5rem;
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.6rem;
            height: 2.6rem;
            background-color: @white;
            border-radius: 50%;
            font-size: 1.3rem;
          }

          span {
            display: none;
          }

          .helpAndSupport {
            .helpIcon {
              margin: 0;
            }
          }
        }

      }

      .menuStrip {
        order: 3;
        height: 100dvh;
        padding: 0;
        opacity: 0;
        position: fixed;
        top: -140%;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background: rgb(255, 255, 255);
        background: linear-gradient(180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 17%,
            rgba(208, 208, 208, 1) 100%);
        transition: all 0.6s ease-in-out;

        // gap: 3rem;
        .nav {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          a {
            text-align: left;
            font-size: 1.9rem;
          }
        }

        .actionBtns {
          flex-direction: column;
          gap: 4rem;

          a,
          .buttonPrimary {
            padding: 1.5rem 2rem;
            font-size: 1.6rem;
          }
        }
      }

      #hamburgerIcon {
        order: 2;
        display: block;
        margin-right: 20px;
        position: relative;
        z-index: 99999;
      }
    }

    &.menuOpen {
      position: fixed;
      top: 0;
      width: 100dvw;
      height: 100dvh;
      z-index: 9999;
      transition: all 0.3s ease-in-out;

      .headerNavSection {
        .menuStrip {
          opacity: 1;
          top: 40px;
        }
      }
    }
  }

  @media screen and (max-height: 600px) and (max-width: @screen-lg) {
    .headerNavSection {
      .menuStrip {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
        padding: 0 60px;

        .nav,
        .actionBtns {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          gap: 3rem 4rem;
          line-height: 1.2;
        }
      }
    }
  }

  @media (max-width: @screen-md) {
    &.menuOpen {


      .headerNavSection {
        .menuStrip {
          top: 5rem;
        }
      }
    }
  }
}

#hamburgerIcon {
  transition: all 0.3s ease-in-out;
  margin-top: 10px;

  .line {
    width: 30px;
    height: 2px;
    background-color: @text_dark_black;
    display: block;
    margin: 7px auto;
    transition: all 0.3s ease-in-out;
  }

  &:is(:hover, :focus-within) {
    cursor: pointer;
  }

  &.is-active {
    animation: smallbig 0.6s forwards;

    & .line:nth-child(1),
    & .line:nth-child(2),
    & .line:nth-child(3) {
      transition-delay: 0.2s;
    }

    & .line:nth-child(2) {
      opacity: 0;
    }

    & .line:nth-child(1) {
      transform: translateY(8px) rotate(50deg);
    }

    & .line:nth-child(3) {
      transform: translateY(-10px) rotate(-50deg);
    }
  }
}

@keyframes smallbig {

  0%,
  100% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(0);
  }
}