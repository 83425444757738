@import "../variables.less";

#features {
    background-color: @white;
    padding-bottom: 5rem;
    
    .main-title {
        max-width: 850px;
        padding: 0 20px;
        margin: 50px auto;
        text-align: center;
        h2{
            font-size: 4.1rem;
            font-weight: 600;
            line-height: 1.3;
            margin: 0;
            text-transform: capitalize;
            text-align: center;
            margin: 0 auto;
            @media (max-width: @screen-md) {
                font-size: 30px;
            }
        }
    }

    .container {
        max-width: 1400px;
    }

    .features {
        isolation: isolate;
        position: relative;

        .container {
            columns: 3;
        }

        .verticalDots {
            position: absolute;
            right: 0;
            bottom: 2rem;
            z-index: -1;
            animation: diving-rotate 4s ease-in-out infinite;
        }

        .featuresContent {
            padding: 3.8rem 2.4rem;
            background-color: @white;
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
            border-radius: 8px;
            transition: 0.5s all ease-in-out;
            position: relative;
            overflow: hidden;
            margin-bottom: 2rem;
            &:hover{
           svg{
            path {
                fill: @primary-color !important;

            }
           }
            }

            .featuresContentBgImg {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 0;
                width: 100%;
                transition: 0.5s all ease-in-out;


                &::after {
                    position: absolute;
                    content: "";
                    height: 100%;
                    width: 100%;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    .themeColorOpacity(@primary-color, 0.8);
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    position: relative;
                }
            }

            .boxContent {
                position: relative;
            }

            .featuresHeader {
                display: flex;
                align-items: center;
                gap: 2rem;

                .featuresIcon {
                    background-color: @primary-color;
                    padding: 1.9rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8.6rem;
                    transition: 0.5s all ease-in-out;

                    svg {
                        height: 4.8rem;
                        width: 4.8rem;
                        path{
                            transition: 0.5s;
                        }
                    }
                }

                .featuresTitle {
                    font-size: 2rem;
                    color: @black;
                    font-weight: 500;
                    position: relative;
                    padding-bottom: 1rem;
                    transition: 0.5s all ease-in-out;

                    &::after {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        width: 2.7rem;
                        height: 0.2rem;
                        left: 0;
                        background-color: @primary-color;
                    }
                }


            }

            .featuresInnerContent {
                p {

                    font-size: 1.6rem;
                    color: #545454;
                    line-height: 1.5;
                    font-weight: 400;
                }
            }

            &:hover {
                .featuresContentBgImg {
                    height: 100%;
                    opacity: 1;
                }

                &::after {
                    height: 100%;
                }

                .featuresHeader {
                    .featuresIcon {
                        background-color: @white;

                        svg {
                            path {
                                fill: @primary-color;
                            }

                        }
                    }

                    .featuresTitle {
                        color: @white;

                        &::after {
                            background-color: @white;
                        }
                    }
                }

                .featuresInnerContent {
                    p {
                        color: @white;
                    }
                }
            }
        }


    }

    .requirement {
        margin: 10rem 0 0 0 !important;
        border-radius: 2rem;
        background: linear-gradient(270deg, #29D3F6 -9.11%, #419AFE 46.07%, #A3E2A5 96.84%);
        align-items: center;
        padding: 5rem;
        position: relative;

        .requiredOne {
            position: absolute;
            top: 0;
        }

        .requiredTwo {
            position: absolute;
            top: 5rem;
            left: 10rem;
        }

        .requiredLeftCircle {
            position: absolute;
            bottom: 0;
            left: 0;
            opacity: 0.4;
        }

        .requiredRightCircle {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: rotate(180deg);
            opacity: 0.3;

            img {
                height: 40rem;
            }
        }

        .requirementInnerContent {
            color: @white;
            max-width: 50%;

            h2 {
                font-size: 4.1rem;
                font-weight: @font-weight-600;
                line-height: 1.3;
                margin: 0;
                text-transform: uppercase;
            }

            p {
                font-size: 2.5rem;
                font-weight: @font-weight-400;
                line-height: 1.3;
                margin: 1rem 0 2rem 0;
                text-transform: capitalize;

                span {
                    display: block;
                    font-size: 2.5rem;
                }
            }
            a{
                margin: 0;
            }
        }

        @media (max-width: @screen-lg) {
            .features {
                display: none;
            }

            padding: 2rem;

            .requirementInnerContent {
                text-align: center;
                width: 100%;
                padding: 0 !important;
                margin-bottom: 20px;
                max-width: 100%;

                h2 {
                    font-size: 2.7rem;
                }

                p {
                    font-size: 2.2rem;

                    span {
                        font-size: 2rem;
                    }
                }
            }

            .requiredTwo {
                left: 0rem;
            }

            .requiredOne {
                right: 0;
            }
        }

        @media (max-width: @screen-md) {

            .container {
                padding: 0 1.5rem;
            }


            .requiredTwo {
                display: none;
            }

            .requirementInnerContent {

                h2 {
                    font-size: 2.5rem;
                }

                p {
                    font-size: 1.6rem;

                    span {
                        font-size: 1.8rem;
                    }
                }
            }

            .requiredRightCircle {
                img {
                    height: 20rem;
                }
            }


        }
    }

    @media (max-width: @screen-lg) {
        .features {
            .container {
                columns: 2;
            }
        }
    }

    @media (max-width: @screen-sm) {
        .features {
            .container {
                columns: 1;

                .featuresContent {
                    .featuresHeader {
                        .featuresIcon {
                            svg {
                                height: 3rem;
                                width: 3rem;
                            }
                        }
                    }

                }
            }
        }
    }
}