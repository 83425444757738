@import "../variables.less";

#career {
    background-color: @white;
    padding-bottom: 5rem;

    .container {
        max-width: 1600px;
        padding: 0 50px;
    }

    .userIcon {
        position: absolute;
        margin: -5rem 0 0 5rem;
        background-color: @primary-color;
        padding: 1.6rem;
        border-radius: 100%;
        width: 90px;
        aspect-ratio: 1/1;
        display: inline-grid;
        place-items: center;
        z-index: 1;

        // img {
        //     filter: invert(50%) sepia(72%) saturate(85%) hue-rotate(0) brightness(200%) contrast(100%);
        // }

        svg path {
            fill: #fff;
        }
    }

    .applyList {
        border-radius: 20px;
        background: @white;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.17);
        padding: 6rem;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 3rem;
        background-image: url("../../images/careerBgImg.png");
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            max-width: 34%;
            background-color: #F9F9F9;
            border-bottom-left-radius: 16.2rem;
            outline: 1px solid #F9F9F9;
            border-left: solid 10px #fff;
            z-index: 0;
        }

        .applyListContent {
            display: flex;
            align-items: flex-start;
            gap: 5rem;

        }



        .title {
            color: @primary-color;
            font-size: 2.2rem;
            font-style: normal;
            font-weight: @font-weight-600;
            margin: 0;
            line-height: 1.5;
            margin: 0 0 0.4rem 0;
        }

        .qualification {

            .qualificationtTitle {

                font-size: 1.5rem;
                font-weight: @font-weight-400;
                color: @black;

            }
        }

        b {
            font-size: 1.8rem;
            font-weight: @font-weight-500;
            color: @black;
            display: block;
        }

        .experienceContent {
            display: flex;
            gap: 5rem;
            align-items: center;

            .ant-divider {
                height: 3rem;
            }
        }

        .applyListBtn {
            display: flex;
            align-items: center;
            gap: 2rem;
            position: relative;
            z-index: 1;

            .buttonLink {
                &:hover {

                    color: @white;
                    border-color: transparent;
                }
            }

            .outline {
                margin: 0;
            }
        }
    }

    .requirement {
        margin: 10rem 0 0 0 !important;
        border-radius: 2rem;
        background: linear-gradient(270deg, #29D3F6 -9.11%, #419AFE 46.07%, #A3E2A5 96.84%);
        align-items: center;
        padding: 5rem;
        position: relative;

        .requiredOne {
            position: absolute;
            top: 0;
        }

        .requiredTwo {
            position: absolute;
            top: 5rem;
            left: 17rem;
        }

        .requiredLeftCircle {
            position: absolute;
            bottom: 0;
            left: 0;
            opacity: 0.4;
        }

        .requiredRightCircle {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: rotate(180deg);
            opacity: 0.3;

            img {
                height: 40rem;
            }
        }

        .requirementInnerContent {
            color: @white;
            max-width: 50%;

            h2 {
                font-size: 4.1rem;
                font-weight: @font-weight-600;
                line-height: 1.3;
                margin: 0;
                text-transform: uppercase;
            }

            p {
                font-size: 2.5rem;
                font-weight: @font-weight-400;
                line-height: 1.3;
                margin: 1rem 0 2rem 0;
                text-transform: capitalize;

                span {
                    display: block;
                    font-size: 2.5rem;
                }
            }
            a{
                margin: 0;
            }
        }
    }

    @media (max-width: @screen-xl) {
        .applyList {
            padding: 5rem 3rem;


            .title {
                font-size: 1.9rem;
            }

            .applyListContent {
                gap: 2rem;
            }

            .experienceContent {
                gap: 2rem;
            }
        }
    }

    @media (max-width: @screen-lg) {
        .applyList {
            align-items: center;

            &:after {
                max-width: 50%;
            }

            .title {
                font-size: 1.6rem;
            }

            .applyListContent {
                flex-direction: column;
            }

        }

        .requirement {
            padding: 2rem;

            .requirementInnerContent {
                text-align: center;
                width: 100%;
                padding: 0 !important;
                max-width: 100%;
                margin-bottom: 20px;

                h2 {
                    font-size: 2.7rem;
                }

                p {
                    font-size: 2.2rem;

                    span {
                        font-size: 2rem;
                    }
                }
            }

            .requiredTwo {
                left: 2rem;
            }

            .requiredOne {
                right: 0;
            }
        }
    }

    @media (max-width: @screen-md) {
        .careerBanner {
            .title {
                font-size: 3rem;
                margin: 1rem;

                &::before,
                &::after {
                    width: 5rem;
                }
            }

            h3 {
                font-size: 1.9rem;
            }

            h4 {
                font-size: 1.6rem;
            }

        }

        .userIcon {
            width: 7rem;
            padding: 1.2rem;
            margin: -4rem 0 0 2rem;
        }

        .applyList {
            flex-direction: column;
            align-items: flex-start;
            padding: 5rem 2rem;



            .applyListContent {
                gap: 1rem;
            }

            b {
                font-size: 1.4rem;
            }
        }

        .container {
            padding: 0 1.5rem;
        }

        .requirement {
            .requiredTwo {
                display: none;
            }

            .requirementInnerContent {

                h2 {
                    font-size: 2.5rem;
                }

                p {
                    font-size: 1.6rem;

                    span {
                        font-size: 1.8rem;
                    }
                }
            }

            .requiredRightCircle {
                img {
                    height: 20rem;
                }
            }

        }
    }

    @media (max-width: @screen-sm) {
        .applyList {
            padding: 3.5rem 2rem;

            &:after {
                display: none;
            }

            .applyListBtn {
                flex-direction: row;
            }
        }
    }

}

.careerDetailsModal {
    width: 70% !important;

    .job_details_list {

        ul {
            li {
                position: relative;
                padding-left: 30px;

                .icon {
                    position: absolute;
                    left: 0;
                    top: 0.6rem;
                }
            }
        }
    }
}

.careerForm {
    width: 850px !important;

    .ant-modal-header {
        text-align: center;

        .ant-modal-title {
            color: @primary-color;
            font-size: 3.2rem;
            font-family: @font-weight-600;
            line-height: 1.3;
            position: relative;
            padding-bottom: 2rem;
            margin-bottom: 3rem;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                width: 8rem;
                height: 0.4rem;
                background-color: @primary-color;
                margin: 0 auto;
                bottom: 0;
            }
        }
    }

    .ant-modal-close {
        right: -2rem;
        top: -2rem;
        height: 4rem;
        width: 4rem;
        border-radius: 4rem;
        background-color: #D9D9D9;
        transition: 0.2s all ease-in-out;

        svg {
            fill: @black;
            height: 2rem;
            width: 2rem;
        }

        &:hover {
            background-color: #D9D9D9;
            transform: scale(0.9);
        }

    }



    .ant-form-item-label {

        label {
            align-items: start;
            font-size: 1.8rem;
            color: @text_dark_black;

            &::before {
                line-height: normal !important;
            }
        }
    }


    .ant-modal-content {
        padding: 4.2rem 4.2rem 1rem 4.2rem;

        .ant-modal-footer {
            text-align: center;
            margin-top: 2rem;
            font-size: 1.7rem;
            font-weight: @font-weight-400;
        }
    }

    .cvUpload {
        background-color: #F3F5F7;
        display: block;
        width: 100%;
        border-radius: 0.7rem;
        text-align: right;
        position: relative;
        isolation: isolate;

        .ant-upload-select {
            font-weight: @font-weight-500;
            color: @white;
            padding: 1.3rem 4rem;
            background-color: @primary-color;
            font-size: 1.8rem;
            font-family: @font-weight-600;
            border-radius: 0.7rem;
            cursor: pointer;
        }

        .ant-upload-list-item-container {
            min-height: 4.5rem;

            .ant-upload-list-item {
                margin-top: 1.3rem
            }


        }

    }

    .uploadImage {
        position: absolute;
        top: 1.4rem;
        left: 2rem;
    }

    .submitForm {
        margin-top: 3rem;
        text-align: center;
    }

    @media (max-width:@screen-lg) {
        .ant-modal-close {
            right: -1rem;
            top: -1rem;
            height: 4rem;
            width: 4rem;

            svg {
                height: 2rem;
                width: 2rem;
            }
        }
    }

    @media (max-width:@screen-sm) {
        .ant-modal-content {
            padding: 2rem;

            .ant-modal-footer {
                font-size: 1.4rem;
            }
        }

        .ant-modal-header {
            .ant-modal-title {
                font-size: 2rem;
            }
        }

        .ant-modal-close {
            right: -1rem;
            top: -1rem;
            height: 3rem;
            width: 3rem;


        }
    }
}