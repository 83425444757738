@import "../variables.less";

#FieldInsPectionModule {
    position: relative;

    .demoBtn {
        margin-top: 2rem;
    }

    .citizensSectionWrap {
        display: flex;
        align-items: center;
        gap: 3rem;
        position: relative;
        z-index: 2;
        justify-content: center;

        .citizensSectionRight {
            img {
                border-radius: 140px;
            }
        }

        .citizensImageBlogOne {
            img {

                border-bottom-left-radius: 180px;
                border-top-right-radius: 140px;
                border-top-left-radius: 140px;
            }
        }

        .citizensImageBlogTwo {
            img {

                border-top-left-radius: 180px;

                border-bottom-left-radius: 140px;
                border-bottom-right-radius: 140px;
            }
        }
    }

    .CityImg {
        position: absolute;
        bottom: -15rem;
        left: 0;
        right: 0;
        z-index: -1;
    }

    .container {
        max-width: 1600px;
    }

    .SolutionForCitizens {
        position: relative;
        background: transparent;
        z-index: 2;



        .Dotted_1 {
            position: absolute;
            left: 0;
            animation: diving-rotate 4s ease-in-out infinite;
        }
    }

    .subTitle {
        color: @primary-color;
        font-family: @primary-font;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        margin: 0;

        &::after {
            position: absolute;
            content: "";
            display: inline-block;
            width: 80px;
            height: 3px;
            background-color: @primary-color;
            top: 0.8rem;
            right: -8.5rem;
        }
    }

    .innerTitle {
        font-size: 4.4rem;
        font-weight: 600;
        text-transform: capitalize;
        margin: 0;
    }

    .content {
        color: @text_light_black;
        font-family: @primary-font;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.32px;
        text-transform: capitalize;
        line-height: 1.6;
    }

    .citizensPicture {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        margin-top: 2rem;

        .citizensImages {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 1rem;

            svg {
                fill: @secondary-color;
                font-size: 2.4rem;
            }
        }

        &:last-child {
            .citizensImages svg {
                display: none;
            }
        }

        h3 {
            color: @primary-color;
            font-family: @primary-font;
            font-size: 2.2rem;
            font-style: normal;
            font-weight: 600;
            text-transform: capitalize;
            margin: 0;
        }

        p {
            color: @text_light_black;
            font-family: @primary-font;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            margin: 0;
            line-height: 1.6;
            text-transform: capitalize;
        }
    }

    .reportSection {
        background-color: #2490EB;
        color: @white;
        padding: 3rem 3rem 7rem 3rem;
        z-index: 2;
        position: relative;
        margin-top: 15rem;

        .squreDotsRight {
            position: absolute;
            right: 0;
            top: 0;
            animation: diving-rotate 4s ease-in-out infinite;
        }

        .squreDotsLeft {
            position: absolute;
            left: 0;
            top: 0;
            animation: diving-rotate 4s ease-in-out infinite;
        }

        .firstLine {
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
        }

        .secondLine {
            position: absolute;
            top: 40%;
            right: 0;
            z-index: -1;
        }

        .titleContent {
            text-align: center;

            .reportTitle {
                font-family: @primary-font;
                font-size: 4.8rem;
                font-weight: 600;
                margin: 0 auto;
                text-transform: capitalize;
                max-width: 1278px;
                width: 100%;
                line-height: 1;
                margin-bottom: 1.6rem;
            }

            .reportSubTitle {
                color: @white;
                font-family: @primary-font;
                font-size: 1.6rem;
                margin: 0;
                font-weight: 600;
                margin-bottom: 2rem;
                text-transform: uppercase;
                display: inline-block;
            }

            .reportContent {
                max-width: 960px;
                width: 100%;
                color: @white;
                text-align: center;
                font-family: @secondary-font;
                font-size: 1.6rem;
                font-weight: 400;
                display: inline-block;
                text-transform: capitalize;
                margin: 0 auto;
            }
        }

        .crmService {
            display: flex;
            align-items: center;
            gap: 3rem;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 3rem;

            .crmServiceContentImage {
                max-width: 42rem;

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }



            .crmServiceTitle {
                color: @white;
                font-family: @primary-font;
                font-size: 2rem;
                font-weight: 600;
                margin: 0;
                text-transform: capitalize;
                position: relative;
                padding-bottom: 1rem;
                line-height: 1.8;

                &::after {
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 0;
                    border-bottom: 0.2rem solid @white;
                    height: auto;
                    width: 4rem;
                }
            }

            .crmServiceList {
                margin-top: 1rem;
                line-height: 1.7;

                svg {
                    transform: rotate(45deg);
                    margin-right: 0.5rem;
                }
            }

        }

        .requiredRightCircle {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: rotate(180deg);
            opacity: 0.3;
        }
    }

    .weAreReady {
        margin-top: 5rem;

        .weAreReadyTitles {
            text-align: center;
        }

        .title {
            color: @titleColor;
            font-family: @primary-font;
            font-size: 4.8rem;
            font-weight: 600;
            text-transform: capitalize;
            margin: 0 auto;
            max-width: 730px;
        }

        .content {
            margin-top: 1rem;

            p {
                margin: 0;
            }
        }

        .inspection {
            margin-top: 4rem;

            .tags {
                background-color: @secondary-color;
                color: @white;
                text-align: center;
                font-family: @primary-font;
                font-size: 2.2rem;
                font-weight: 600;
                text-transform: capitalize;
                padding: 2rem 0;
                border-radius: 2rem;
                margin: 0;
            }

            .inspectionContent {
                background-color: rgba(113, 191, 68, 10%);
                border-bottom-left-radius: 2rem;
                border-bottom-right-radius: 2rem;
                padding: calc(3rem + 30px) 2rem 6rem 2rem;
                margin: -30px 0 0 0;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                color: @text_light_black;
                text-align: center;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 400;
                text-transform: capitalize;
            }
        }
    }

    .featuresOfField {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 3rem 0 5rem 0;
        position: relative;
        overflow: hidden;
        z-index: 2;
        margin-top: 8rem;


        .featuresOfFieldLines {
            position: absolute;
            top: -15rem;
            width: 2rem;
            z-index: 1;

            svg {
                height: auto;
                width: auto;
            }
        }

        .rightDots {
            text-align: right;
            position: relative;
            z-index: 2;
        }

        .leftDots {
            position: relative;
            z-index: 2;
        }

        .fieldInspectionContent {
            text-align: center;
            color: @white;
            position: relative;
            z-index: 2;

            svg {
                border: 2px solid @white;
                height: 98px;
                width: 98px;
                border-radius: 98px;
                padding: 1rem;
            }

            h2 {
                max-width: 69rem;
                margin: 0 auto;
                color: @white;
                text-align: center;
                font-family: @primary-font;
                font-size: 4.8rem;
                text-transform: capitalize;
                font-weight: 600;
            }
        }
    }

    .demoForm {
        position: relative;

        .featuresOfFieldLines {
            position: absolute;
            top: 0;
        }

        .squreDotsRight {
            position: absolute;
            right: 0;
            top: -50px;
        }

        .reportSection {
            margin: 0 0 10rem 0;
            padding: 3rem 3rem 0 3rem;
        }

        .miniContainer {
            max-width: 1400px;
            margin: 0 auto;
        }

        .crmServiceTitle {
            color: @white;
            font-family: @primary-font;
            font-size: 3.2rem;
            font-style: normal;
            font-weight: 600;
            text-transform: capitalize;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                left: 0;
                bottom: 0;
                border-bottom: 0.2rem solid @white;
                height: auto;
                width: 4rem;
            }
        }

        .crmServiceList {
            color: @white;
            font-family: @secondary-font;
            font-size: 2rem;
            font-style: normal;
            font-weight: 400;
            text-transform: capitalize;
            line-height: 1.8;

            svg {
                transform: rotate(45deg);
                margin-right: 0.5rem;
            }
        }

        .contactForm {
            // transform: translateY(-105px);
            margin-top: -105px;
            padding: 2rem 3rem;
            position: relative;

            .crmServiceTitle {
                color: @black;
                margin: 0 0 2rem 0;

                &:after {
                    border-bottom: 0.2rem solid @primary-color;
                }
            }

            background: @white;
            border-radius: 2rem;
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);

            .nameInput {
                display: flex;
                align-items: center;
                gap: 2rem;
            }

            label {
                color: @text_light_black;
                font-family: @primary-font;
                font-size: 1.7rem;
                font-style: normal;
                font-weight: 400;
                text-transform: capitalize;
            }

            input,
            textarea {
                padding: 0;
                background: transparent;
                border-bottom: 1px solid @black;
                border-radius: 0;
            }

            .ant-btn-default {
                padding: 0.8rem 1.5rem;
                margin-bottom: 24px;
                width: 100%;
                height: auto;
                border-radius: 10px;
                background-color: #2490eb;
                color: #fff;
                border: 1px solid #2490eb;
                img{display: none;}

                &:hover {
                    background-color: #71bf44;
                    border-color: #71bf44;
                    color: #fff;
                }
            }
        }

        .forCitizens {
            background: linear-gradient(270deg, #29D3F6 -9.11%, #419AFE 46.07%, #A3E2A5 96.84%);
            position: relative;
            z-index: 2;
            width: 100%;
            text-align: center;
            transform: translateY(13rem);
            padding: 7rem 0;
            border-radius: 2rem;

            .requiredTwo {
                position: absolute;
                left: 5rem;
                top: 0;
            }

            .CitizensDots {
                position: absolute;
                right: 6rem;
                top: 4rem;
            }

            .CitizensDotsBottom {
                position: absolute;
                left: 5rem;
                bottom: 2rem;
            }
            .subTitle {
                color: @white;
                font-family: @primary-font;
                font-size: 2.5rem;
                font-style: normal;
                font-weight: 400;
                margin: 0;
                list-style: 1.5;
                text-transform: capitalize;
                &::after{
                    display: none;
                }
            }

            h2 {
                margin: 0;
                color: @white;
                font-family: @primary-font;
                font-size: 4.1rem;
                font-style: normal;
                font-weight: 600;
                list-style: 1.5;
                text-transform: uppercase;
            }
        }
    }
    .demoForm{
        .contactForm{
            .nameInput{
                .ant-form-item{
                    width: 100%;
                    max-width: 50%;
                }
            }
            textarea, input{
                width: 100%;
                height: auto;
                background-color: #fff;
                border-radius: 12px;
                padding: 1rem 1.5rem;
                color: #000;
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 1.3;
                resize: none;
                border-width: 1px;
                border-style: solid;
                border-color: #d9d9d9;
                &:focus{
                    border-color: #4096ff;
                    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
                    outline: 0;
                }
            }
        }
    }


    @media (max-width: @screen-lg) {
        .reportSection {
            .titleContent {
                .reportTitle {
                    font-size: 3.5rem;
                }
            }
        }

        .weAreReady {
            .title {
                font-size: 3.5rem;
            }
        }

        .featuresOfField {
            .fieldInspectionContent {
                h2 {
                    font-size: 4.1rem;
                }
            }
        }

        .reportSection {
            .crmService {
                .crmServiceContentImage {
                    max-width: 30rem;
                }
            }
        }

        .demoForm {
            .contactForm {
                margin-top: 0;
            }

            .forCitizens {
                .CitizensDots {
                    right: -10rem;
                }
            }
        }

    }

    @media (max-width: @screen-md) {
        .innerTitle {
            font-size: 4rem;
        }

        .reportSection {
            .titleContent {
                .reportTitle {
                    font-size: 3rem;
                }
            }
        }
        
        .weAreReady {
            .title {
                font-size: 3rem;
            }
        }
        .reportSection {
            .crmService {
                .crmServiceContentImage {
                    max-width: 45rem;
                }
            }
        }
        .featuresOfField {
            .fieldInspectionContent {
                h2 {
                    font-size: 2rem;
                }
            }
        }

        .demoForm {
            .forCitizens {
                h2 {
                    font-size: 3rem;
                }

                .requiredTwo {
                    left: 0;
                }
            }

            .CitizensDotsBottom,
            .CitizensDots {
                display: none;
            }

        }
    }

    @media (max-width: @screen-sm) {
        .subTitle{
            &::after{
            width: 50px;
            right: -6rem;
            }
        }
        .demoForm {
            .contactForm{
                .nameInput{
                    flex-wrap: wrap;
                    gap: 0;
                    .ant-form-item{
                        max-width: 100%;
                    }
                }
            }
        }
    
        .innerTitle {
            font-size: 2.2rem;
        }

        .CityImg {
            bottom: 0;
        }

        .reportSection {
            margin-top: 5rem;

            .container {
                padding: 0;
            }

            .titleContent {
                .reportTitle {
                    font-size: 2.2rem;
                }
            }

            .squreDotsRight,
            .squreDotsLeft {
                display: none;
            }
        }

        .weAreReady {

            .title {
                font-size: 2.2rem;
            }

            .inspection {
                .tags {
                    font-size: 1.8rem;
                }
            }
        }

        .leftDots,
        .rightDots {
            display: none;
        }

        .demoForm {
            .crmServiceTitle {
                font-size: 2rem;
            }

            .crmServiceList {
                font-size: 1.4rem;
            }

            .forCitizens {
                transform: translateY(8rem);
                padding: 5rem 0;

                .subTitle {
                    font-size: 1.8rem;
                }

                h2 {
                    font-size: 2rem;
                }
            }
        }
    }


}