@primary-color: #2490eb;
@primary-light-color: #b4ddff;
@secondary-color: #71bf44;
@secondary-dark-color: #539c28;
@black: #000;
@white: #fff;
@text_dark_black: #263238;
@text_light_black: #565969;

@tagColor: #3d4d5b;
@titleColor: #0c1620;

@lite_gray: #f1f1f1;

@action_btn_color: #696b71;
@success-color: #52c41a;
@warning-color: #faad14;
@error-color: #f5222d;
@label-color: #181c32;
@input-bg: #f3f5f7;

@primary-font: "Poppins", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
  "Lucida Sans", Arial, sans-serif;
@secondary-font :"Oswald", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
"Lucida Sans", Arial, sans-serif;

@gutter: 24px;
@text-color: #494949;

// Main Header Variables
@layout-body-background: #f7f7f7;
@layout-header-background: @white;
@layout-header-color: @text-color;
@layout-sidebar-background: @primary-color;
@layout-header-height: 125px;

// Action Button Color variables
@orange-btn: #ffa500;
@red-btn: #f10000;
@blue-btn: #0f01ff;

// Main Header Logo Variables
@logo-height: 42px;

// Section Variables
@section-padding: 50px;
@screen-sm: 620px;
@screen-md: 768px;
@screen-lg: 992px;
@screen-sxl: 1024px;
@screen-xl: 1300px;
@screen-xxl: 1700px;

@font-weight-400 : 400;
@font-weight-500 : 500;
@font-weight-600 : 600;
@font-weight-700 : 700;
@font-weight-800 : 800;

.themeColorOpacity(@rgbColor, @opacity) {
  background-color: rgba(@rgbColor, @opacity);
}