@import "../variables.less";

#projectTracking {
    position: relative;
    .administrationSection{
        padding-top: 60px;
        .container{
            max-width: 1600px;
        }
        .titleContent{
            .Title{
                font-size: 48px;
                font-weight: @font-weight-600;
                line-height: 52px;
                color: #0C1620;
                text-align: center;
                margin: 0 0 20px 0 ;
            }
            .description{
                text-align: center;
                color: #565969;
                font-size: 19px;
                font-weight: @font-weight-400;
                line-height: 26px;
                margin-top: 0;
            }
        }
        .administrationMain{
            display: flex;
            justify-content: center;
            gap: 20px;
            .administrationBox{
                width: 25%;
                padding: 80px 0 0;
                position: relative;
                transition: all 0.5s ease-in-out;
                height: 100%;
                &:hover{
                    padding-top: 20px;
                }
                .administrationBoxInner{
                    text-align: left;
                    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.06);
                    padding: 45px 30px;
                    border-radius: 10px;
                    background-color: #fff;
                    position: relative;
                    height: 100%;
                    .icon{
                        position: relative;
                        img{
                            width: 50px;
                            height: 50px;
                        }
                        &::after{
                            content: "";
                            display: block;
                            position: absolute;
                            z-index: 1;
                            left: 15px;
                            top: -20px;
                            right: auto;
                            width: 50px;
                            height: 50px;
                            border-radius: 50px;
                            background-color: rgba(113, 191, 68, 0.2);
                            
                        }
                    }
                    h3{
                        font-size: 2rem;
                        font-weight: 500;
                        line-height: 1.4;
                        color: #000;
                        margin: 0;
                        padding: 10px 0; 
                        &:after{
                            content: "";
                            display: block;
                            width: 44px;
                            height: 4px;
                            border-radius: 6px;
                            background-color: #2490eb;
                            margin-top: 12px;
    
                        }
                    }
                    p{
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.4;
                        color: rgba(86, 89, 105, 0.9);
                        margin-bottom: 0;
                    }
                    &::after{
                        content: "";
                        width: 0%;
                        height: 7px;
                        border-radius: 7px;
                        background-color: #2490eb;
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        opacity: 0;
                        transition: all 0.5s ease-in-out;
                    }
                    &:hover{
                        &::after{
                            opacity: 1;
                            width: 100%;
                            left: 0;
                        }
                    }
                }
            }


        }
    }
    .ProjectTrackingModule {
        position: relative;
        padding-top: 30px;
        .ProjectTrackingModuleInner {
            display: flex;
            align-items: center;
            gap: 30px;
            >div{
                width: 50%;
            }
            .gps-road-img {
                text-align: center;
                img {
                    max-width: 500px;
                    margin-bottom: -63px;
                
                }
            }

            .titleContent {
                max-width: 630px;
                .Title {
                    font-size: 48px;
                    line-height: 52px;
                    font-weight: 600;
                    color: #0C1620;
                    margin-top: 0;
                }
                .description{
                    font-size: 19px;
                    font-weight: @font-weight-400;
                    line-height: 26px;
                    color: #272E32;
                }

            }
        }
        &::after{
            content: "";
            height: 286px;
            width: 286px;
            border-radius: 100%;
            top: auto;
            bottom: 0;
            left: -70px;
            z-index: 99;
            position: absolute;
            background: rgb(116, 194, 71);
            background: linear-gradient(90deg, rgba(116, 194, 71, 0.24) 0%, rgba(180, 221, 255, 0) 100%);
            transform: rotate(140deg);
        }
    }
     .requiredRightCircle {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
        opacity: 0.3;
    }

    .gps-road-app-work {
        display: flex;
        .gps-work-left-content {
            width: 50%;
            padding-right: 20px;
            .titleContent {
                .Title {
                    font-size: 48px;
                    font-weight: 600;
                    line-height: 52px;
                }
                .description{
                    font-size: 16px;
                    font-weight: @font-weight-400;
                    line-height: 26px;
                }
            }
            .gpsRoadList{
                padding-top: 50px;
                li {
                   font-size: 18px;
                   font-weight: 500;
                   position: relative;
                   padding-left: 42px;
                   padding-top: 4px;
                   margin-bottom: 20px;
                   span{
                       position: absolute;
                       left: 0;
                       background: @white;
                       padding: 11px 10px;
                       border-radius: 50px;
                       top: 0;
                       width: 32px;
                       height: 32px;
                       display: flex;
                       justify-content: center;
                       align-items: center;
                   }
               }
           }
        }
        .gps-road-work-img{
            padding-left: 20px;
            img{
                position: absolute;
                left: auto;
                right: 0;
                width: 50%;
                bottom: 0;
            }
        }
    }
    
    .demoBtn {
        margin-top: 2rem;
    }

    .citizensSectionWrap {
        display: flex;
        align-items: center;
        gap: 3rem;
        position: relative;
        z-index: 2;
        justify-content: center;

        .citizensSectionRight {
            img {
                border-radius: 140px;
            }
        }

        .citizensImageBlogOne {
            img {

                border-bottom-left-radius: 180px;
                border-top-right-radius: 140px;
                border-top-left-radius: 140px;
            }
        }

        .citizensImageBlogTwo {
            img {

                border-top-left-radius: 180px;

                border-bottom-left-radius: 140px;
                border-bottom-right-radius: 140px;
            }
        }
    }

    .CityImg {
        position: absolute;
        bottom: -15rem;
        left: 0;
        right: 0;
        z-index: -1;
    }

    .container {
        max-width: 1400px;
    }

    .SolutionForCitizens {
        position: relative;
        background: transparent;
        z-index: 2;



        .Dotted_1 {
            position: absolute;
            left: 0;
            animation: diving-rotate 4s ease-in-out infinite;
        }
    }

    .subTitle {
        color: @primary-color;
        font-family: @primary-font;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        margin: 0;

        &::after {
            position: absolute;
            content: "";
            display: inline-block;
            width: 80px;
            height: 3px;
            background-color: @primary-color;
            top: 0.8rem;
            right: -8.5rem;
        }
    }

    .innerTitle {
        font-size: 4.4rem;
        font-weight: 600;
        text-transform: capitalize;
        margin: 0;
    }
   
    .content {
        color: @text_light_black;
        font-family: @primary-font;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.32px;
        text-transform: capitalize;
        line-height: 1.6;
    }
    .Road-Checking-Module-list{
        margin-top: 40px;
        .citizensPicture {
            &:last-child{
                .arrowIcon{
                    display: none;
                }
            }
            .arrowIcon{
                width: 96px;
                display: flex;
                justify-content: center;
                margin-top: 15px;
            }
            .citizensPictureInner {
                display: flex;
                align-items: center;
                gap: 0;
                margin-top: 15px;
                width: 100%;
                max-width: 450px;
    
                .citizensImages {
                    width: 96px;
                    height: 96px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .citizensContent{
                    margin: 0;
                    padding: 0;
                    width: calc(100% - 96px);
                    padding-left: 10px;
                }
            }    
            h3 {
                color: @primary-color;
                font-family: @primary-font;
                font-size: 2.2rem;
                font-style: normal;
                font-weight: 600;
                text-transform: capitalize;
                margin: 0;
            }
    
            // p {
            //     color: @text_light_black;
            //     font-family: @primary-font;
            //     font-size: 1.6rem;
            //     font-style: normal;
            //     font-weight: 400;
            //     margin: 0;
            //     line-height: 1.6;
            //     text-transform: capitalize;
            // }
        }
    }


    .reportSection {
        background-color: #2490EB;
        color: @white;
        padding: 5rem 3rem 7rem 3rem;
        z-index: 2;
        position: relative;
        margin-top: 10rem;

        .squreDotsRight {
            position: absolute;
            right: 0;
            top: 0;
            animation: diving-rotate 4s ease-in-out infinite;
        }

        .squreDotsLeft {
            position: absolute;
            left: 0;
            top: 0;
            animation: diving-rotate 4s ease-in-out infinite;
        }

        .secondLine {
            position: absolute;
            top: 40%;
            right: 0;
            z-index: -1;
        }

        &:after{
            height: 84px;
            width: 84px;
            content: "";
            position: absolute;
            top: 95px;
            z-index: -1;
            margin-left: -30px;
            border-radius: 100%;
            background: rgb(219, 243, 211);
            background: linear-gradient(90deg, rgba(219, 243, 211, 0.7931547619047619) 0%, rgba(36, 144, 235, 0.7903536414565826) 100%);
            transform: rotate(90deg);
        }
        &::before{
            content: "";
            position: absolute;
            bottom: -100px;
            left: -100px;
            height: 286px;
            width: 286px;
            border-radius: 100%;
            background: rgb(180, 221, 255);
            background: linear-gradient(90deg, rgba(180, 221, 255, 0.8) 0%, rgba(255, 255, 255, 0.6923144257703081) 100%);
            background: rgb(180, 221, 255);
            background: linear-gradient(90deg, rgba(180, 221, 255, 0) 0%, rgba(255, 255, 255, 0.24) 100%);
        }

    }

    .featuresOfField {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 3rem 0 5rem 0;
        position: relative;
        overflow: hidden;
        z-index: 2;


        .featuresOfFieldLines {
            position: absolute;
            top: -15rem;
            width: 2rem;
            z-index: 1;

            svg {
                height: auto;
                width: auto;
            }
        }

        .rightDots {
            text-align: right;
            position: relative;
            z-index: 2;
        }

        .leftDots {
            position: relative;
            z-index: 2;
        }

        .fieldInspectionContent {
            text-align: center;
            color: @white;
            position: relative;
            z-index: 2;

            svg {
                border: 2px solid @white;
                height: 98px;
                width: 98px;
                border-radius: 98px;
                padding: 1rem;
            }

            h2 {
                max-width: 69rem;
                margin: 0 auto;
                color: @white;
                text-align: center;
                font-family: @primary-font;
                font-size: 4.8rem;
                text-transform: capitalize;
            }
        }
    }

    .demoForm {
        position: relative;

        .featuresOfFieldLines {
            position: absolute;
            top: 0;
        }

        .squreDotsRight {
            position: absolute;
            right: 0;
            top: -50px;
        }

        .reportSection {
            margin: 0 0 10rem 0;
            padding: 3rem 3rem 0 3rem;
        }

        .miniContainer {
            max-width: 1400px;
            margin: 0 auto;
        }

        .crmServiceTitle {
            color: @white;
            font-family: @primary-font;
            font-size: 3.2rem;
            font-style: normal;
            font-weight: 600;
            text-transform: capitalize;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                left: 0;
                bottom: 0;
                border-bottom: 0.2rem solid @white;
                height: auto;
                width: 4rem;
            }
        }

        .crmServiceList {
            color: @white;
            font-family: @secondary-font;
            font-size: 2rem;
            font-style: normal;
            font-weight: 400;
            text-transform: capitalize;
            line-height: 1.8;

            svg {
                transform: rotate(45deg);
                margin-right: 0.5rem;
            }
        }

        .contactForm {
            // transform: translateY(-105px);
            margin-top: -105px;
            padding: 2rem 3rem;
            position: relative;

            .crmServiceTitle {
                color: @black;
                margin: 0 0 2rem 0;

                &:after {
                    border-bottom: 0.2rem solid @primary-color;
                }
            }

            background: @white;
            border-radius: 2rem;
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);

            .nameInput {
                display: flex;
                align-items: center;
                gap: 2rem;
            }

            label {
                color: @text_light_black;
                font-family: @primary-font;
                font-size: 1.7rem;
                font-style: normal;
                font-weight: 400;
                text-transform: capitalize;
            }

            input,
            textarea {
                padding: 0;
                background: transparent;
                border-bottom: 1px solid @black;
                border-radius: 0;
            }

            .ant-btn-default {
                padding: 0.8rem 1.5rem;
                margin-bottom: 24px;
                width: 100%;
                height: auto;
                border-radius: 10px;
                background-color: #2490eb;
                color: #fff;
                border: 1px solid #2490eb;
                img{display: none;}

                &:hover {
                    background-color: #71bf44;
                    border-color: #71bf44;
                    color: #fff;
                }
            }
        }

        .forCitizens {
            background: linear-gradient(270deg, #29D3F6 -9.11%, #419AFE 46.07%, #A3E2A5 96.84%);
            position: relative;
            z-index: 2;
            width: 100%;
            text-align: center;
            transform: translateY(13rem);
            padding: 7rem 0;
            border-radius: 2rem;

            .requiredTwo {
                position: absolute;
                left: 5rem;
                top: 0;
            }

            .CitizensDots {
                position: absolute;
                right: 6rem;
                top: 4rem;
            }

            .CitizensDotsBottom {
                position: absolute;
                left: 5rem;
                bottom: 2rem;
            }

            .subTitle {
                color: @white;
                font-family: @primary-font;
                font-size: 2.5rem;
                font-style: normal;
                font-weight: 400;
                margin: 0;
                list-style: 1.5;
                text-transform: capitalize;
                &::after{
                    display: none;
                }
            }

            h2 {
                margin: 0;
                color: @white;
                font-family: @primary-font;
                font-size: 4.1rem;
                font-style: normal;
                font-weight: 600;
                list-style: 1.5;
                text-transform: uppercase;
            }
        }
    }
    .demoForm{
        .contactForm{
            .nameInput{
                .ant-form-item{
                    width: 100%;
                    max-width: 50%;
                }
            }
            textarea, input{
                width: 100%;
                height: auto;
                background-color: #fff;
                border-radius: 12px;
                padding: 1rem 1.5rem;
                color: #000;
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 1.3;
                resize: none;
                border-width: 1px;
                border-style: solid;
                border-color: #d9d9d9;
                &:focus{
                    border-color: #4096ff;
                    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
                    outline: 0;
                }
            }
        }
    }

    @media (max-width: @screen-xl) {
        
        .administrationSection {
            .administrationMain {
                gap: 20px;
                flex-wrap: wrap;
                margin-top: 50px;
                .administrationBox{
                    width: 100%;
                    padding: 0px 0 0;
                    position: relative;
                    transition: all 0.5s ease-in-out;
                    max-width: 450px;
                    height: inherit;
                    &:hover{
                        padding: 0;
                    }
                }
                }
            }
    
        .reportSection{
            .crmService{
                gap: 2rem;
                .crmServiceContent{
                    padding: 10px;
                }
            }
        }
        .gps-road-app-work{
            .gps-work-left-content{
                .titleContent {
                    .Title {
                        font-size: 40px;
                        line-height: 45px;
                    }
                }
                .gpsRoadList{
                    padding-top: 20px;
                }
                .gpsRoadList{
                    li{
                        font-size: 16px;
                        font-weight: @font-weight-400;
                    }
                }
            }
        }
        .ProjectTrackingModule {
            .ProjectTrackingModuleInner {
                .titleContent {
                    .Title {
                        font-size: 40px;
                        margin-bottom: 0;
                    }
                }
            }
        }
        .innerTitle{
            font-size: 40px;
        }
       
    }

    @media (max-width: @screen-sxl) {
        .administrationSection {
            .administrationMain {
                .administrationBox{
                    max-width: 350px;
                }
                }
            }
    

        .reportSection{
            .crmService{
                z-index: 1;
                position: relative;
                flex-wrap: wrap;
                .crmServiceContent{
                    width: 100%;
                    max-width: 48%;
                    padding: 20px;
                    margin-bottom: 50px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .crmServiceTitle{
                        line-height: 30px;
                        font-size: 24px;
                    }
                    .description{
                        font-size: 16px;
                    }
                }
            }
        }
        .gps-road-app-work {
            display: block;
            .gps-work-left-content{
                width: 100%;
                padding: 0;
            }
            .gps-road-work-img {
                padding: 0;
                text-align: center;
                margin-top: 20px;
                img{
                    position: relative;
                    margin: 0 auto;
                    width: auto;
                }
            }
        }
        .ProjectTrackingModule {
            .ProjectTrackingModuleInner {
                .titleContent {
                    .Title {
                        font-size: 29px;
                        line-height: 36px;
                    }
                }
            }
        }

    }
    @media (max-width: @screen-lg) {
        .reportSection {
            .titleContent {
                .reportTitle {
                    font-size: 3.5rem;
                }
            }
        }

        .gpsAttendance {
            .title {
                font-size: 3.5rem;
            }
        }

        .featuresOfField {
            .fieldInspectionContent {
                h2 {
                    font-size: 4.1rem;
                }
            }
        }

        .reportSection {
            .crmService {
                .crmServiceContentImage {
                    max-width: 30rem;
                }
            }
        }

        .demoForm {
            .contactForm {
                margin-top: 0;
            }

            .forCitizens {
                .CitizensDots {
                    right: -10rem;
                }
            }
        }

    }

    @media (max-width: @screen-md) {
        .administrationSection {
            .titleContent{
                .Title{
                    font-size: 32px;
                    line-height: 36px;
                }
                .description{
                    font-size: 16px;   
                }
            }
           
            .administrationMain {
                .administrationBox{
                    max-width: 450px;
                    .administrationBoxInner{
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
                }
            }
        .gps-road-app-work {
            .gps-work-left-content {
                .titleContent {
                    .Title {
                        font-size: 30px;
                        line-height: 36px;
                    }
                }
            }
        }

        .ProjectTrackingModule {
            .ProjectTrackingModuleInner {
                gap: 0px;
                display: block;
                > div {
                    width: 100%;
                }
                .titleContent {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 20px;
                }
                .gps-road-img{
                    img{
                        max-width: 100%;
                        margin-bottom: -50px;
                    }
                }
            }
        }
        .Road-Checking-Module-list{
            margin-top: 30px;
            .citizensPicture {
                .arrowIcon{
                    width: 50px;
                }
                .citizensPictureInner {
                    .citizensImages {
                        width: 50px;
                        height: 50px;
                    }
                    .citizensContent{
                        width: calc(100% - 50px);
                        h3{
                            font-size: 20px;
                            line-height: 28px;
                        }
                    }
                }
    
            }
        }
        .gpsAttendance{
            &::after{
                height: 126px !important;
                width: 126px !important;
                left: -50px !important;

            }
        }
        .innerTitle {
            font-size: 4rem;
        }
        .reportSection {
            .crmService{
                display: block;
                .crmServiceContent{
                    max-width: 400px;
                    width: 100%;
                    margin: 0 auto 80px auto;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }

            }
            .titleContent {
                .reportTitle {
                    font-size: 3rem;
                }
            }
        }
        
        .reportSection {
            .crmService {
                .crmServiceContentImage {
                    max-width: 45rem;
                }
            }
        }
        .featuresOfField {
            .fieldInspectionContent {
                h2 {
                    font-size: 2rem;
                }
            }
        }

        .demoForm {
            .forCitizens {
                h2 {
                    font-size: 3rem;
                }

                .requiredTwo {
                    left: 0;
                }
            }

            .CitizensDotsBottom,
            .CitizensDots {
                display: none;
            }

        }
    }

    @media (max-width: @screen-sm) {
        .subTitle{
            &::after{
            width: 50px;
            right: -6rem;
            }
        }
        .demoForm {
            .contactForm{
                .nameInput{
                    flex-wrap: wrap;
                    gap: 0;
                    .ant-form-item{
                        max-width: 100%;
                    }
                }
            }
        }
    
        .innerTitle {
            font-size: 2.2rem;
        }

        .CityImg {
            bottom: 0;
        }

        .reportSection {
            margin-top: 5rem;

            .container {
                padding: 0;
            }

            .titleContent {
                .reportTitle {
                    font-size: 2.2rem;
                }
            }

            .squreDotsRight,
            .squreDotsLeft {
                display: none;
            }
        }

        .gpsAttendance {

            .title {
                font-size: 2.2rem;
            }

            .inspection {
                .tags {
                    font-size: 1.8rem;
                }
            }
        }

        .leftDots,
        .rightDots {
            display: none;
        }

        .demoForm {
            .crmServiceTitle {
                font-size: 2rem;
            }

            .crmServiceList {
                font-size: 1.4rem;
            }

            .forCitizens {
                transform: translateY(8rem);
                padding: 5rem 0;

                .subTitle {
                    font-size: 1.8rem;
                }

                h2 {
                    font-size: 2rem;
                }
            }
        }
    }


    .gpsAttendance{
        position: relative;
        &::after{
            background: rgb(180, 221, 255);
            background: linear-gradient(90deg, rgba(180, 221, 255, 0) 0%, rgba(116, 194, 71, 0.22) 100%);
            position: absolute;
            height: 286px;
            width: 286px;
            z-index: 99;
            top: auto;
            left: -130px;
            content: "";
            bottom: -50px;
            border-radius: 50%;
            z-index: 0;
        
        }
        &::before{
            content: "";
            position: absolute;
            top: auto;
            height: 84px;
            width: 84px;
            background: #DBF3D3;
            border-radius: 50%;
            z-index: 0;
            right: -30px;
            bottom: 100px;


        }
    }
}
