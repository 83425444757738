@import "../variables.less";

.pricing_main_section {
  padding-top: 80px;
  background-color: @white;

  .container {
    max-width: 1700px;

    >.heading {
      width: 100%;
      text-align: center;

      h2 {
        line-height: 1.6;
        font-size: 4.4rem;
        margin: 0;
      }


      p {
        font-size: 1.6rem;
        padding: 10px 0;
        width: 100%;
        margin: 0 auto;
        line-height: 1.6;
        margin-bottom: 3rem;
      }
    }
  }

  // .pricing_tab {
  //   background-color: #F5F5F5;
  // }
  .ant-tabs-top {
    .ant-tabs-nav-list {
      .ant-tabs-ink-bar {
        display: none;
      }
    }
  }

  .ant-tabs-nav {
    margin: 0;
    background-color: @white;

    &::before {
      display: none;
    }

    .ant-tabs-nav-wrap {
      justify-content: center;
      transition: 0.5s all ease-in-out;

      .ant-tabs-tab {
        padding: 1rem 2.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1PX solid rgba(0, 0, 0, 0.3);
        color: #565969;
        border-radius: 60px;
        box-shadow: none;

      }

      .ant-tabs-tab-active {
        padding: 1rem 2.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 60px;
        border: 1PX solid @primary-color;
        background-color: @primary-color;
        box-shadow: 0px 4px 24px 0px #b4ddff;

        .ant-tabs-ink-bar {
          display: none !important;
        }

        .ant-tabs-tab-btn {
          color: @white;
        }
      }

    }
  }

  .priceTable {
    text-align: left;
    border-collapse: separate;
    // border-spacing: 0.2rem 3rem;

    h2{
      position: relative;
      width: 100%;
      max-width: 160px;

        span{
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          color: @white;
        }
    }

    td {

      width: 18%;
      padding: 2.5rem;
      background-color: @white;

      &:first-child {
        width: 432px;
      }

      .innerContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    @media (max-width: 768px) {

      display: block;


      tr {
        display: block;
        margin-bottom: 10px;
      }

      td {
        width: 100%;
        display: block;

        &:first-child {
          width: 100%;
        }
      }

      .innerContent {
        margin-bottom: 10px;
      }
    }
  }
}