@import "../variables.less";

#solutions {
    background-color: @white;
    position: relative;

    .roundDots {
        position: absolute;
        top: 25rem;
        animation: diving-rotate 4s ease-in-out infinite;

    }

    .thirdSectionImgLeft,
    .thirdSectionImgRight,
    .lastSectionImgLeft {
        position: absolute;
        bottom: 150rem;
        opacity: 0.3;
        animation: diving-rotate 4s ease-in-out infinite;
    }

    .thirdSectionImgRight {
        right: 0;
        bottom: 130rem;
    }

    .lastSectionImgLeft {
        bottom: 8rem;
    }

    .container {
        max-width: 1400px;
    }

    .solutionDetails {
        .innerContent {
            padding: 5rem 0;
        }

        &:nth-child(2) {
            .dots {
                height: 2.7rem;
                display: inline-block;
                width: 2.7rem;
                border-radius: 2.7rem;
                animation: bubbles 3s infinite linear
            }

            .solutionContent {
                .dots {
                    position: absolute;
                    background-color: #CCDDEF;
                    left: -5rem;
                    top: 10rem;
                    animation: bubbles 3s infinite linear
                }
            }

            .solutionImg {
                .dots {
                    background-color: #E5F3DF;
                    position: absolute;
                    bottom: 5rem;
                }
            }
        }

        &:nth-child(odd) {
            background-color: #EAF4FD;
            background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, #EAF4FD 6%, #EAF4FD 94%, rgba(255, 255, 255, 1) 100%);
            box-shadow: inset 0 -0 100px 80px rgba(255, 255, 255, 1);
        }


        .solutionContent {
            .contentTitle {
                margin: 0 0 2.9rem 0;
                color: @black;
                font-size: 4.4rem;
                font-weight: 600;
                text-transform: capitalize;
                line-height: 1.6;
                position: relative;
                padding-bottom: 2rem;

                &::after {
                    position: absolute;
                    content: "";
                    height: 0.3rem;
                    width: 5.2rem;
                    left: 0;
                    bottom: 0;
                    background-color: @primary-color;
                }
            }

            .content {
                color: #545454;
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 1.4;
            }
        }

        .solutionImg {
            text-align: center;

            img {
                display: inline-block;
                object-fit: contain;
                object-position: center;
                aspect-ratio: 16/13;
            }
        }

        @media (min-width:@screen-lg) {
            &:nth-child(even) {
                .solutionContent {
                    order: 1;
                }

                .solutionImg {
                    order: 2;
                }
            }
        }
    }

    @media (max-width:@screen-md) {
        .roundDots {
            display: none;
        }

        .thirdSectionImgLeft,
        .thirdSectionImgRight,
        .lastSectionImgLeft {
            display: none;

        }
    }

    @media (max-width:@screen-sm) {
       

        .solutionDetails {
            margin-bottom: 5rem;
            .innerContent {
                padding: 1rem 0;
            }

            .solutionContent {

                .contentTitle {
                    font-size: 2.5rem;
                }
            }
        }

    }
}